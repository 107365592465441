// Setting up env based configuration
var getEnv = process.env.REACT_APP_ENV;
var domain = window.location.origin;

let env = {};

const envList = [
  {
    domain: domain + "/summary",
    BRANDS: [
      "mgp",
      "mgp-sau",
      "mgp-are",
      "stb",
      "bbc",
      "orh",
      "orh-sau",
      "orh-are",
      "tmc",
      "tmc-sau",
      "tmc-are",
      "srn",
      "srn-sau",
      "srn-are",
      "lit",
      "blu",
      "popxo",
      "orb",
      "mnm",
      "tbb",
    ],
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "ggg",
    IMAGE_URL: "/images/g3-logo.png",
    TITLE: "The Good Glamm Group",
    FAV_ICON: "/images/g3-icon.png",
    isProduction: false,
  },

  {
    domain: domain + "/summary",
    BRANDS: [
      "mgp",
      "mgp-sau",
      "mgp-are",
      "stb",
      "bbc",
      "orh",
      "orh-sau",
      "orh-are",
      "tmc",
      "tmc-sau",
      "tmc-are",
      "srn",
      "srn-sau",
      "srn-are",
      "lit",
      "blu",
      "popxo",
      "orb",
      "mnm",
      "tbb",
    ],
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "ggg",
    IMAGE_URL: "/images/g3-logo.png",
    TITLE: "The Good Glamm Group",
    FAV_ICON: "/images/g3-icon.png",
    isProduction: true,
  },

  {
    domain: "https://mis.myglamm.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "edfecef6ff6cc50f39eff3fed437533f",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "mgp",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.myglamm.net/sa",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "bb7892cdfb6e692839bef723647362ba",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "mgp-sau",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm (SA)",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.myglamm.net/ae",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "0aefc8d172e3cf4f5b83b2e8031ed603",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "mgp-are",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm (AE)",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.stbotanica.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "aaf3a54157834e82e9a074c89d9f04b6",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "stb",
    IMAGE_URL: "/images/stblogo.png",
    TITLE: "St.Botanica",
    FAV_ICON: "/images/stb-favicon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.babychakra.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "8d6f33b784211f834ff4c883bff70b0b",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "bbc",
    IMAGE_URL: "/images/babychakra-logo.png",
    TITLE: "Baby Chakra",
    FAV_ICON: "/images/babychakra-favicon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.organicsharvest.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "9f95493b09cf6b9ff31198a7bb15865b",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "orh",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.organicsharvest.net/sa",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "8b151a1a60cf6c3687d2c1db364ff0ef",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "orh-sau",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest (SA)",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: false,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.organicsharvest.net/ae",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "7a4f9df0586d96ffa78fcf9a93d21522",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "orh-are",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest (AE)",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: false,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.themomsco.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "6f830a02c876cb2395431eeb43ac7d47",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "tmc",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co",
    FAV_ICON: "/images/momscologo.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.themomsco.net/sa",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "e0e8a2cd4892c10505500d88571af1db",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "tmc-sau",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co (SA)",
    FAV_ICON: "/images/momscologo.png",
    isProduction: false,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.themomsco.net/ae",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "4a5f961739ae7accdaf4f1809d6b701b",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "tmc-are",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co (AE)",
    FAV_ICON: "/images/momscologo.png",
    isProduction: false,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.thesirona.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "7b4483f3279a3b846a93f7d060ab593a",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "srn",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.thesirona.net/sa",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "13df7ac5d1a837ad828f02fdfa06dcec",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "srn-sau",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona (SA)",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: false,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.thesirona.net/ae",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "ff07b070652eeff34f99e6adc36fc1b3",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "srn-are",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona (AE)",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: false,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.litbymyglamm.flashco.in/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "14b2dbec85f2e7e961516f35441586a5",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "lit",
    IMAGE_URL: "/images/lit-logo.png",
    TITLE: "LIT",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.bleuofficial.flashco.in/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "3cfd62deb79e0d814d050c0c08d5847e",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "blu",
    IMAGE_URL: "/images/bleu-logo.png",
    TITLE: "Bleu",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.popxo.net/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "4ac6596ab01795c0f7ee893d31791a7b",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "popxo",
    IMAGE_URL: "/images/popxo-logo.png",
    TITLE: "POPxo",
    FAV_ICON: "/images/popxo-favicon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.orientalbotanics.flashco.in/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "e42172cc5801303c717e10d55ce2ffb2",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "orb",
    IMAGE_URL: "/images/orb-logo.png",
    TITLE: "Oriental Botanics",
    FAV_ICON: "/images/orb-favicon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.manishmalhotramakeup.flashco.in/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "821f977382c5a35bd3bd91f51eae7430",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "mnm",
    IMAGE_URL: "/images/mnm-logo.png",
    TITLE: "Manish Malhotra",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.trybeforeyoubuy.flashco.in/",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "2719d8a623d35d43148659d3fcb2bf36",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "tbb",
    IMAGE_URL: "/images/tbb-logo.png",
    TITLE: "TryBeforeYouBuy",
    FAV_ICON: "/images/tbb-favicon.png",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.myglamm.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "bd3172105640f31556df4e2b35254408",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "mgp",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm",
    FAV_ICON: "/favicon.ico",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.myglamm.com/sa",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "91d8a432ccd25b59524a496a977b6332",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "mgp-sau",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm (SA)",
    FAV_ICON: "/favicon.ico",
    isProduction: true,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.myglamm.com/ae",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "1bfbb7616f3d6914e8a7f85225bc0570",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "mgp-are",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm (AE)",
    FAV_ICON: "/favicon.ico",
    isProduction: true,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.stbotanica.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "dc96baf43ed2a8ac1249947cd1022436",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "stb",
    IMAGE_URL: "/images/stblogo.png",
    TITLE: "St.Botanica",
    FAV_ICON: "/images/stb-favicon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.babychakra.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "9e53c68faa06daedcbbf2c779d1354d1",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "bbc",
    IMAGE_URL: "/images/babychakra-logo.png",
    TITLE: "Baby Chakra",
    FAV_ICON: "/images/babychakra-favicon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.themomsco.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "6674ebd84a965e21cbc7879522a517d0",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "tmc",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co",
    FAV_ICON: "/images/momscologo.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.themomsco.com/sa",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "3af76849c6a62dfc83d6cf55d97bcb56",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "tmc-sau",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co (SA)",
    FAV_ICON: "/images/momscologo.png",
    isProduction: true,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.themomsco.com/ae",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "d97dde807413a6b2844e309c31aecd2d",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "tmc-are",
    IMAGE_URL: "/images/themomsco.png",
    TITLE: "The Moms Co (AE)",
    FAV_ICON: "/images/momscologo.png",
    isProduction: true,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.organicharvest.in/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "bbd43c691ac9c266aba9999865088e7f",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "orh",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.organicharvest.in/sa",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "11195a4d90e7c1402ca3574d92d57cc0",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "orh-sau",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest (SA)",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: true,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.organicharvest.in/ae",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "a1eccd758e847643447c7b01a2c174ae",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "orh-are",
    IMAGE_URL: "/images/orhlogo.png",
    TITLE: "Organic Harvest (AE)",
    FAV_ICON: "/images/orh-icon.png",
    isProduction: true,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.thesirona.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "b21180d5ea4e6f5a338c9c57a67122de",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "srn",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.thesirona.com/sa",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "8825e39f5b4dfc503b6a2275d57880ef",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "srn-sau",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona (SA)",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: true,
    COUNTRY_CODE: "SAU",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.thesirona.com/ae",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "dad8f2a0eb8e16d16f215c38a4726698",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "srn-are",
    IMAGE_URL: "/images/sirona-logo.png",
    TITLE: "The Sirona (AE)",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: true,
    COUNTRY_CODE: "ARE",
    REGION: "MIDDLE_EAST",
  },

  {
    domain: "https://mis.litbymyglamm.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "a9a4c98455658a1cc17e2a1b80c79efb",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "lit",
    IMAGE_URL: "/images/lit-logo.png",
    TITLE: "LIT",
    FAV_ICON: "/favicon.ico",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.bleuofficial.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "6ef764e4599c5b91b6d960d3ca13c047",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "blu",
    IMAGE_URL: "/images/bleu-logo.png",
    TITLE: "Bleu",
    FAV_ICON: "/images/srn-ico.jpg",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.popxo.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "988316fd3c499c89d684b273a17f414f",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "popxo",
    IMAGE_URL: "/images/popxo-logo.png",
    TITLE: "POPxo",
    FAV_ICON: "/images/popxo-favicon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.orientalbotanics.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "288f0f259ac412dcedcb1edc5faa6f62",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "orb",
    IMAGE_URL: "/images/orb-logo.png",
    TITLE: "Oriental Botanics",
    FAV_ICON: "/images/orb-favicon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.manishmalhotramakeup.com/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "511488768824291943ff9b94b5c176ad",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "mnm",
    IMAGE_URL: "/images/mnm-logo.png",
    TITLE: "Manish Malhotra",
    FAV_ICON: "/favicon.ico",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "https://mis.trybeforeyoubuy.co.in/",
    apiurl: "https://acl.mgapis.com",
    API_KEY: "bdc4191d396ff59bdea38c7d0c570f98",
    GOOGLE_TOKEN:
      "833943087863-8v4f5n92p7f34jrskp6a76c53d79m0cn.apps.googleusercontent.com",
    VENDOR_CODE: "tbb",
    IMAGE_URL: "/images/tbb-logo.png",
    TITLE: "TryBeforeYouBuy",
    FAV_ICON: "/images/tbb-favicon.png",
    isProduction: true,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },

  {
    domain: "default",
    apiurl: "https://mag.myglamm.net",
    API_KEY: "edfecef6ff6cc50f39eff3fed437533f",
    GOOGLE_TOKEN:
      "909846177158-d8b783553cbj3di0evm1csn48og2bqlv.apps.googleusercontent.com",
    VENDOR_CODE: "mgp",
    IMAGE_URL: "/images/logo-myglamm.png",
    TITLE: "MyGlamm",
    FAV_ICON: "/favicon.ico",
    isProduction: false,
    COUNTRY_CODE: "IND",
    REGION: "APAC",
  },
];

// find and set env based on URL
const setEnv = (envList, domain, path) => {
  const fullURL = path ? domain + path : domain
    return envList.find(
      (vendor) => vendor.domain === fullURL || vendor.domain === "default"
    );
}; 

// for The Good Glamm Group report to make multiple API calls with allowed vendor codes
const setEnvByVendorCode = (envList, brand) => {
  const filteredEnvList = envList.filter((item) => getEnv === "production" ? item.isProduction === true
    : item.isProduction === false && item.domain !== "default");
  return filteredEnvList.find(
    (vendor) => vendor.VENDOR_CODE === brand || vendor.domain === "default"
  );
}; 

// helper to select required properties from env object
const selectProps = (...props) => {
  return (obj) => {
    const newObj = {};
    props.forEach((name) => {
      newObj[name] = obj[name];
    });

    return newObj;
  };
}

// creating list of vendors for dropdown menu with required properties
const vendorsList = envList
  .filter((item) =>
    getEnv === "production"
      ? item.isProduction === true
      : item.isProduction === false && item.domain !== "default"
  )
  .map(selectProps("domain", "TITLE", "FAV_ICON", "VENDOR_CODE"));

env = setEnv(envList, domain, window.location.pathname);

export { env, vendorsList, envList, setEnvByVendorCode };