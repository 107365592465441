import React, { useState, useEffect, createContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

import MyGlammAPI from "../lib/MyglammAPI";
import DailySalesReport from "./dailySalesReport";
import DateSelection from "./dateSelection";
import MonthlySalesOverviewMobile from "./mobile/monthlySalesOverviewMobile";
import DailySalesReportMobile from "./mobile/dailySalesReportMobile";
import dayjs from "dayjs";
import Error from "./common/error";
import { checkIfConfigExists, formatAmount, getCurrency, checkIfOrdersHaveIncreasedProportionally, checkAmountWithPreviousMonthsDate } from "../utils/helper";
import NProgress from "nprogress";
import { env } from "../lib/config";
import VendorSelectorModal from "./common/vendorSelectorModal";
import GoodGlammAPI from "../lib/GoodGlammAPI";
import DailySalesReportMobileSummary from "./mobile/dailySalesReportMobileSummary";
import DailySalesReportSummary from "./dailySalesReportSummary";
import MonthlySalesOverviewMobileSummary from "./mobile/monthlySalesOverviewMobileSummary";
import FilterModal from "./common/filterModal"
import NotificationBadge, { Effect } from "react-notification-badge"
import SnapshotSummary from "./common/snapshotSummary";

export const DailySalesDataContext = createContext();

function Dashboard() {
  const [dailySalesData, setDailySalesData] = useState();
  const [salesOverview, setSalesOverview] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [todayBtnClicked, setTodayBtnClicked] = useState(false);
  const [weekClicked, setWeekClicked] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [error, setError] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [GMV, setGMV] = useState(true);
  const [showOfflineSales, setShowOfflineSales] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [salesOverviewDataWithOfflineSales, setSalesOverviewDataWithOfflineSales] = useState();
  const [salesOverviewDataWithoutOfflineSales, setSalesOverviewDataWithoutOfflineSales] = useState();
  const [visibleBrands, setVisibleBrands] = useState();

  const activeFilters = [!GMV, showOfflineSales];
  const myglammApi = new MyGlammAPI();

  const [firstDate, setFirstDate] = useState();
  const [lastDate, setLastDate] = useState();

  const [updatedAt, setUpdatedAt] = useState(new Date())

  const [modalIsOpenVendor, setIsOpenVendor] = React.useState(false);

  const allBrands = checkIfConfigExists(
    JSON.parse(localStorage.getItem("allowedVendorCodes")) || env.BRANDS
  );

  const openVendorModal = () => {
    setIsOpenVendor(true);
  }

  const closeVendorModal = () => {
    setIsOpenVendor(false);
  }

  const [modalIsOpenFilter, setIsOpenFilterModal] = React.useState(false);

  const openFilterModal = () => {
    setIsOpenFilterModal(true);
  };

  const closeFilterModal = () => {
    setIsOpenFilterModal(false);
  };

  /* set default current month for keeping current month in dropdown selected */
  const [currentMonth, setCurrentMonth] = useState(
    dayjs().startOf("month").format("MMM YYYY")
  );

  useEffect(() => {
    const localDate = localStorage.getItem("localDate");
    if (
      localDate &&
      dayjs(localDate, "YYYY-MM-DD").format("YYYY-MM-DD") === localDate
    ) {
      var startDte = dayjs(localDate).startOf("month").format("YYYY-MM-DD");
      var lastDte = dayjs(localDate).endOf("month").format("YYYY-MM-DD");

      setFirstDate(startDte);
      setLastDate(lastDte);
      setCurrentMonth(dayjs(localDate).startOf("month").format("MMM YYYY"));
      if (
        localStorage.getItem("today") &&
        JSON.parse(localStorage.getItem("today"))
      ) {
        getToday();
      } else if (
        localStorage.getItem("week") &&
        JSON.parse(localStorage.getItem("week"))
      ) {
        getCurrentWeek();
      }
    } else {
      setFirstDate(dayjs().startOf("month").format("YYYY-MM-DD"));
      setLastDate(dayjs().endOf("month").format("YYYY-MM-DD"));
    }
  }, []);

  const getToday = () => {
    let todayDate = dayjs(new Date()).format("YYYY-MM-DD");
    /* set today's date */
    setFirstDate(todayDate);
    setLastDate(todayDate);
    setTodayBtnClicked(true);
    setWeekClicked(false);
    setCurrentMonth(0);
    localStorage.setItem("today", true);
    localStorage.removeItem("week");
  };

  const getSelectedMonth = (month) => {
    let date = new Date(dayjs("1 " + month).format("YYYY-MM-DD"));
    var firstDay = dayjs(date).startOf("month").format("YYYY-MM-DD");
    var lastDay = dayjs(date).endOf("month").format("YYYY-MM-DD");
    /* set start and end date of selected month from dropdown */
    setFirstDate(firstDay);
    setLastDate(lastDay);
    setTodayBtnClicked(false);
    setWeekClicked(false);
    setCurrentMonth(month);
    localStorage.removeItem("week");
    localStorage.removeItem("today");
  };

  const getCurrentWeek = () => {
    let startDateOfWeek = dayjs()
      .startOf("week")
      .format("YYYY-MM-DD");
    let endDateOfWeek = dayjs()
      .endOf("week")
      .format("YYYY-MM-DD");

    /* set start and end date of current week */
    setFirstDate(startDateOfWeek);
    setLastDate(endDateOfWeek);
    setTodayBtnClicked(false);
    setWeekClicked(true);
    setCurrentMonth(0);
    localStorage.setItem("week", true);
    localStorage.removeItem("today");
  };

  /* calcualte order amount on basis of GMV */
  const getOrderAmount = (reportData, GMV = false) => {
    let orderAmount = reportData?.payableAmount ? reportData.payableAmount : 0;
    if (GMV) {
      orderAmount = reportData?.orderAmount;
    }

    orderAmount = Math.round(orderAmount); 

    return orderAmount;
  };

  const getAverageOrderValue = (reportData, GMV = false, brand) => {
    let orderAmount = reportData?.payableAmount ? reportData.payableAmount : 0;
    if (GMV) {
      orderAmount = reportData?.orderAmount;
    }

    orderAmount = Math.round(orderAmount);
    let averageOrderValue = orderAmount / reportData?.orderCount;
    averageOrderValue = Math.round(averageOrderValue);
    averageOrderValue = formatAmount(averageOrderValue, getCurrency(brand));
    return averageOrderValue;
  };

  /* download GMV or Non GMV report */
  const downloadGMV = (GMV) => {
    let downloadParam = "nongmv";
    if (GMV) {
      downloadParam = "gmv";
    }
    myglammApi
      .downloadSalesDataGMV(firstDate, lastDate, downloadParam)
      .then(({ data }) => {
        var blob = new Blob([data], {
          type: "application/vnd.ms-excel",
        });
        var objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.setAttribute(
          "download",
          `product-sales-${firstDate}-${lastDate}.xlsx`
        );
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /* get daily sales and overall sales data in one */
  const getProductSalesDataAllInOne = () => {
    NProgress.start();

    // check if this is for the summarized good glamm report
    if (env.VENDOR_CODE === "ggg") {

      // build API instances array based on allowed vendor codes
      let instances = [];

      allBrands.forEach((brand) => {
        instances.push(new GoodGlammAPI(brand));
      });

      const promises = instances.map((instance) =>
        instance.getProductSalesDataAllInOne(
          firstDate,
          lastDate,
          instance.APIVendor
        )
      );

      Promise.allSettled([...promises])
        .then(function (values) {
          NProgress.done();

          // build an array of required fields from the API responses
          const fetchedData = values.map((brand) => ({
            data: brand?.value?.data?.data.saleReport.rows,
            total: brand?.value?.data.data.saleReport.total,
            vendorCode: brand?.value.config.vendor,
          }));

          // for monthly grand total of all brands
          let salesOverviewData = {};

          // storing results on first call to toggle results for showOfflineSales filter (grand total) - reduces another call
          let saveSalesOverviewDataWithOfflineSales = {};
          let saveSalesOverviewDataWithoutOfflineSales = {};

          allBrands.forEach((brand) => {
            const vendorObj = fetchedData.find((vendor) => vendor.vendorCode === brand);
            saveSalesOverviewDataWithOfflineSales[vendorObj.vendorCode] = vendorObj?.total?.grand;
            saveSalesOverviewDataWithoutOfflineSales[vendorObj.vendorCode] = vendorObj?.total?.online;
            if (vendorObj)
              salesOverviewData[vendorObj.vendorCode] = localStorage.getItem("showOfflineSales") === "true" ? vendorObj?.total?.grand : vendorObj?.total?.online;
          });

          setSalesOverviewDataWithOfflineSales(saveSalesOverviewDataWithOfflineSales);
          setSalesOverviewDataWithoutOfflineSales(saveSalesOverviewDataWithoutOfflineSales);

          // group the response data with required keys & sorting
          let brandWiseDataArray = [];
          fetchedData.forEach((brand) => {
            let salesData = brand.data;
            salesData = Object.keys(salesData)?.map((key) => ({
              date: new Date(key),
              sales: salesData[key],
            }));
            salesData.sort((date1, date2) => date2.date - date1.date);
            brandWiseDataArray.push(salesData);
          });

          // group the data of all brands by same date
          var aggregateDataArray = [];
          brandWiseDataArray.forEach(function (item) {
            item.forEach(function (subitem, index) {
              var existing = aggregateDataArray.filter(function (v, i) {
                return v.date.getTime() === subitem.date.getTime();
              });
              if (existing.length) {
                var existingIndex = aggregateDataArray.indexOf(existing[0]);
                aggregateDataArray[existingIndex].sales = aggregateDataArray[
                  existingIndex
                ].sales.concat(subitem.sales);
              } else {
                if (typeof subitem.sales == "object" && subitem.sales !== null)
                  subitem.sales = [subitem.sales];
                aggregateDataArray.push(subitem);
              }
            });
          });

          var patchedAggregateDataArray = [];
          aggregateDataArray.forEach((data, index, arr) => {
            const currentDateSales = data;
            const prevDateSales = arr[index + 1];
            const patchedSalesDataForTheDay = Object.keys(data.sales).map((brand) => {
              let mergedSalesForTheDay = "";
              allBrands.forEach((vendorCode) => {
                // Pick the current date order for the selected vendor code
                const currentDate = currentDateSales?.sales?.find(
                  (b) => b.grand?.vendorCode === vendorCode
                );
                
                // Pick the previous date order for the selected vendor code
                const prevDayOrder =
                  prevDateSales &&
                  prevDateSales?.sales.find(
                    (v) => v.grand?.vendorCode === vendorCode
                  );

                const alertRaisedOnline = checkIfOrdersHaveIncreasedProportionally(
                  prevDayOrder?.online,
                  currentDate?.online
                );

                const alertRaisedGrand = checkIfOrdersHaveIncreasedProportionally(
                  prevDayOrder?.grand,
                  currentDate?.grand
                );

              const alertRaised = alertRaisedOnline || alertRaisedGrand;

                if (alertRaised && vendorCode === data.sales[brand].grand.vendorCode) {
                  let newGrand = {};
                  let newOnline = {};
                  if (alertRaisedGrand) {
                    newGrand = {
                      ...currentDate.grand,
                      raiseAlert: true,
                    };
                  }
                  if (alertRaisedOnline) {
                  newOnline = {
                    ...currentDate.online,
                    raiseAlert: true,
                  };
                  }
                  mergedSalesForTheDay = Object.assign(
                    {},
                    currentDate,
                    { grand: newGrand },
                    { online: newOnline }
                  );
                }
              })

              if (mergedSalesForTheDay) {
                 return {
                   ...mergedSalesForTheDay,
                 };
               } 
              return { ...data.sales[brand]};
              }
            );

            const patchedData = {
              date: data.date,
              sales: patchedSalesDataForTheDay,
            };
            patchedAggregateDataArray.push(patchedData);

          });
          setDailySalesData(patchedAggregateDataArray);
          setSalesOverview(salesOverviewData);
          setError(false);
          setErrorStatus(false);
        })
        .catch((err) => {
          NProgress.done();
          setError(err.response?.data);
          setErrorStatus(true);
          console.log("error", err.response?.data);
        });
    } else {

      /* Create two separate instances for making API call of current & previous month */
      const instances = 2;
      let todaysDate = dayjs(new Date()).format("YYYY-MM-DD");
      let promises = [];
      for (let i = 0; i < instances; i++) {
        const instance = new MyGlammAPI();
        promises.push(
          instance.getProductSalesDataAllInOne(
            dayjs(firstDate).subtract(i, "month").format("YYYY-MM-DD"),
            dayjs(currentMonth === dayjs(todaysDate).startOf("month").format("MMM YYYY") ? todaysDate : lastDate).subtract(i, "month").format("YYYY-MM-DD"),
            instance.APIVendor
          )
        );
      }
      Promise.allSettled([...promises])
        .then(function (values) {
          NProgress.done();

          /* For daily sales total column */
          let combinedDataWithStatus = {};
          const selectedMonthData = values[0]?.value?.data?.data;
          const previousMonthData = values[1]?.value?.data?.data;

          const selectedMonthDates = Object.keys(
            selectedMonthData?.saleReport?.rows
          );

          selectedMonthDates.forEach((currentDate) => {
            const prevDate = dayjs(currentDate)
              .subtract(1, "month")
              .format("YYYY-MM-DD");

            /* Get grand total amount for current & previous month */
            const currentDaysTotal =
              selectedMonthData?.saleReport?.rows[currentDate]?.grand
                ?.orderAmount;
            const previousMonthDaysTotal =
              previousMonthData?.saleReport?.rows[prevDate]?.grand?.orderAmount;

            const totalAmountStatus = checkAmountWithPreviousMonthsDate(
              currentDaysTotal,
              previousMonthDaysTotal
            );

            /* create new grand sales object with status */
            let updatedGrandTotalWithStatus = {};
            updatedGrandTotalWithStatus = {
              ...selectedMonthData?.saleReport?.rows[currentDate]?.grand,
              status: totalAmountStatus,
            };

            /* Get online sales total amount for current & previous month */
            const currentDaysOnlineTotal =
              selectedMonthData?.saleReport?.rows[currentDate]?.online
                ?.orderAmount;
            const previousMonthDaysOnlineTotal =
              previousMonthData?.saleReport?.rows[prevDate]?.online
                ?.orderAmount;

            const totalOnlineAmountStatus = checkAmountWithPreviousMonthsDate(
              currentDaysOnlineTotal,
              previousMonthDaysOnlineTotal
            );

            /* create new online sales object with status */
            let updatedOnlineTotalWithStatus = {};
            updatedOnlineTotalWithStatus = {
              ...selectedMonthData?.saleReport?.rows[currentDate]?.online,
              status: totalOnlineAmountStatus,
            };

            /* create new daily sales object with new grand and online child objects */
            const newDaySalesDataWithStatus = Object.assign(
              {},
              selectedMonthData?.saleReport?.rows[currentDate],
              { grand: updatedGrandTotalWithStatus },
              { online: updatedOnlineTotalWithStatus }
            );

            combinedDataWithStatus = {
              ...combinedDataWithStatus,
              [currentDate]: { ...newDaySalesDataWithStatus },
            };
          });

          /* For monthly sales overview row */
          const selectedMonthChannels = Object.keys(
            selectedMonthData?.saleReport?.total
          );
          let newSalesOverviewObjectWithStatus = {};
          selectedMonthChannels.forEach((element) => {
            const orderAmountStatus = checkAmountWithPreviousMonthsDate(
              selectedMonthData?.saleReport?.total?.[element]?.orderAmount,
              previousMonthData?.saleReport?.total?.[element]?.orderAmount
            );
            const orderCountStatus = checkAmountWithPreviousMonthsDate(
              selectedMonthData?.saleReport?.total?.[element]?.orderCount,
              previousMonthData?.saleReport?.total?.[element]?.orderCount
            );

            let updatedChannelObject = {};

            updatedChannelObject = {
              ...selectedMonthData?.saleReport?.total?.[element],
              orderAmountStatus: orderAmountStatus,
              orderCountStatus: orderCountStatus,
            };

            newSalesOverviewObjectWithStatus = {
              ...newSalesOverviewObjectWithStatus,
              [element]: { ...updatedChannelObject },
            };
          });

          /* Logic to raise alert if order amount does not increase proportionally */
          let salesData = combinedDataWithStatus;
          salesData = Object.keys(salesData)?.map((key) => ({
            date: new Date(key),
            sales: salesData[key],
          }));

          salesData.sort((date1, date2) => date2.date - date1.date);
          var patchedDailySalesData = [];
          salesData.forEach((data, index, arr) => {
            const todaySales = data;
            const yesterdaySales = arr[index + 1];
            let mergedSales = {};

            const alertRaisedGrand = checkIfOrdersHaveIncreasedProportionally(
              yesterdaySales?.sales?.grand,
              todaySales?.sales?.grand
            );
            const alertRaisedOnline = checkIfOrdersHaveIncreasedProportionally(
              yesterdaySales?.sales?.online,
              todaySales?.sales?.online
            );

            const alertRaised = alertRaisedOnline || alertRaisedGrand;

            if (alertRaised) {
              let newDailyGrand = {};
              let newDailyOnline = {};

              if (alertRaisedGrand) {
                newDailyGrand = {
                  ...todaySales?.sales?.grand,
                  raiseAlert: true,
                };
              }

              if (alertRaisedOnline) {
                newDailyOnline = {
                  ...todaySales?.sales?.online,
                  raiseAlert: true,
                };
              }

              mergedSales = Object.assign(
                {},
                todaySales?.sales,
                { grand: newDailyGrand },
                { online: newDailyOnline }
              );
            }

            const patchedDailyData = {
              date: data.date,
              sales: Object.keys(mergedSales).length
                ? mergedSales
                : todaySales?.sales,
            };
            patchedDailySalesData.push(patchedDailyData);
          });

          setDailySalesData(patchedDailySalesData);
          setSalesOverview(newSalesOverviewObjectWithStatus);
          setError(false);
          setErrorStatus(false);
        })
        .catch((err) => {
          NProgress.done();
          setError(err.response?.data);
          setErrorStatus(true);
          console.log("error", err.response?.data);
        });      
    }
  };

  const refreshData = () => {
    setUpdatedAt(new Date());
    getProductSalesDataAllInOne();
  };

  /* call api whenever firstdate and lastdate is changed */
  useEffect(() => {
    if (firstDate && lastDate) {
      getProductSalesDataAllInOne();
      setUpdatedAt(new Date());
    }
    const interval = setInterval(() => {
      refreshData()
    }, 30000)

    return () => clearInterval(interval)
  }, [firstDate, lastDate]); //eslint-disable-line react-hooks/exhaustive-deps

  // toggle to include offline sales in the good glamm report
  useEffect(() => {
    if (salesOverviewDataWithOfflineSales && salesOverviewDataWithoutOfflineSales)
      showOfflineSales ? setSalesOverview(salesOverviewDataWithOfflineSales) : setSalesOverview(salesOverviewDataWithoutOfflineSales);

    if (env.VENDOR_CODE === "ggg")
      activeFilters.push(visibleBrands?.length > 0);
    setFilterCount(activeFilters.filter(Boolean).length);
  }, [showOfflineSales, GMV, visibleBrands]); //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const getGMV = localStorage.getItem("GMV");
    if (getGMV) {
      if (getGMV === "true") {
        setGMV(true);
      } else {
        setGMV(false);
      }
    }
    else {
      localStorage.setItem("GMV", GMV)
    }
  }, []);

  useEffect(() => {
    let tabIndex = localStorage.getItem("tabIndex");

    if (tabIndex) {
      tabIndex = JSON.parse(tabIndex);
      setSelectedTabIndex(tabIndex);
    }
  }, []);

  // store showOfflineSales in localStorage for handling auto-refresh
  useEffect(() => {
    const getOfflineSales = localStorage.getItem("showOfflineSales");
    if (getOfflineSales) {
      // re-initlaize state on first load from localStorage
      if (getOfflineSales === "true")
        setShowOfflineSales(true);
      else
        setShowOfflineSales(false);
    }
    else {
      localStorage.setItem("showOfflineSales", showOfflineSales);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getVisibleBrands = JSON.parse(localStorage.getItem("visibleBrands"));
    if (getVisibleBrands?.length > 0) {
      setVisibleBrands(getVisibleBrands);
    } else {
      localStorage.setItem("visibleBrands", JSON.stringify(allBrands));
      setVisibleBrands(allBrands);
    }
  }, [])

  const customStyles = {
    overlay: { zIndex: 1000 },
  };

  return (
    <DailySalesDataContext.Provider
      value={{
        dailySalesData,
        showOfflineSales: showOfflineSales,
        setShowOfflineSales: setShowOfflineSales,
        GMV: GMV,
        setGMV: setGMV,
        allBrands: allBrands,
        visibleBrands: visibleBrands,
        setVisibleBrands: setVisibleBrands,
      }}
    >
      {!errorStatus ? (
        <div className="container">
          <div className={!dailySalesData ? "hide" : ""}>
            <div>
              <div className="headerTabs">
                <div className="vendor-selector">
                  <button className="vendorBtn" onClick={openVendorModal}>
                    {env?.TITLE ? env.TITLE : "Please Select"}
                    <img
                      style={{ marginLeft: "5px" }}
                      src="/images/dropdown-black.svg"
                      alt="arrow"
                      height="10px"
                      width="10px"
                    />
                  </button>
                  <VendorSelectorModal
                    modalIsOpenVendor={modalIsOpenVendor}
                    closeVendorModal={closeVendorModal}
                    customStyles={customStyles}
                  />
                </div>

                <div className="menu">
                  <div className="text-center title">
                    <h1>Product Sales Report</h1>
                  </div>

                  <div className="filter-button">
                    <img
                      className="filter-icon"
                      src="/images/filters.png"
                      alt="arrow"
                      height="25px"
                      width="25px"
                      onClick={openFilterModal}
                    />
                    <NotificationBadge
                      count={filterCount}
                      effect={Effect.SCALE}
                      frameLength={15.0}
                      style={{
                        color: "white",
                        backgroundColor: "#ff9797",
                        top: "-32px",
                        right: "-12px",
                        padding: "3px",
                        fontSize: "10px",
                      }}
                    />
                  </div>

                  <FilterModal
                    modalIsOpenFilter={modalIsOpenFilter}
                    closeFilterModal={closeFilterModal}
                    customStyles={customStyles}
                  />
                </div>
              </div>

              <div className="section">
                <div className="content">
                  <div className="snapshot-container">
                    <DateSelection
                      getToday={getToday}
                      getCurrentWeek={getCurrentWeek}
                      firstDate={firstDate}
                      lastDate={lastDate}
                      getSelectedMonth={getSelectedMonth}
                      todayBtnClicked={todayBtnClicked}
                      weekClicked={weekClicked}
                      currentMonth={currentMonth}
                      downloadGMV={downloadGMV}
                      GMV={GMV}
                    />

                    <div className="datePickerMobile">
                      <span>
                        {dayjs(firstDate).format("ddd,D MMM")} to{" "}
                        {dayjs(lastDate).format("ddd,D MMM")}
                      </span>
                    </div>

                    {/* Snapshot Summary */}
                    {env.VENDOR_CODE === "ggg" && (
                      <SnapshotSummary
                        GMV={GMV}
                        getOrderAmount={getOrderAmount}
                        visibleBrands={visibleBrands}
                        salesOverview={salesOverview}
                        cssClass="desktop-only"
                      />
                    )}
                  </div>

                  {dailySalesData && dailySalesData.length > 0 ? (
                    env.VENDOR_CODE === "ggg" ? (
                      <>
                        <div className="mobileDetailedReports">
                          <Tabs
                            onSelect={(tabIndex) => {
                              setSelectedTabIndex(tabIndex);
                              localStorage.setItem("tabIndex", tabIndex);
                            }}
                            selectedIndex={selectedTabIndex}
                          >
                            <TabList className="mobileReports">
                              <Tab
                                onClick={() => {
                                  getProductSalesDataAllInOne();
                                }}
                              >
                                <h2>Daily Report</h2>
                              </Tab>
                              <Tab>
                                <h2>Grand Total</h2>
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <DailySalesReportMobileSummary
                                firstDate={firstDate}
                                lastDate={lastDate}
                                GMV={GMV}
                                getOrderAmount={getOrderAmount}
                                dailySalesData={dailySalesData}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                                dashboardUpdatedAt={updatedAt}
                                refreshDataBtn={refreshData}
                                showOfflineSales={showOfflineSales}
                              />
                            </TabPanel>
                            <TabPanel>
                              <MonthlySalesOverviewMobileSummary
                                firstDate={firstDate}
                                lastDate={lastDate}
                                GMV={GMV}
                                getOrderAmount={getOrderAmount}
                                salesOverview={salesOverview}
                                todayBtnClicked={todayBtnClicked}
                                weekClicked={weekClicked}
                                getAverageOrderValue={getAverageOrderValue}
                              />
                            </TabPanel>
                          </Tabs>
                        </div>
                        <DailySalesReportSummary
                          firstDate={firstDate}
                          lastDate={lastDate}
                          GMV={GMV}
                          getOrderAmount={getOrderAmount}
                          dailySalesData={dailySalesData}
                          setSalesOverview={setSalesOverview}
                          salesOverview={salesOverview}
                          getAverageOrderValue={getAverageOrderValue}
                          dashboardUpdatedAt={updatedAt}
                          refreshDataBtn={refreshData}
                          showOfflineSales={showOfflineSales}
                        />
                      </>
                    ) : (
                      <>
                        <DailySalesReport
                          firstDate={firstDate}
                          lastDate={lastDate}
                          GMV={GMV}
                          getOrderAmount={getOrderAmount}
                          dailySalesData={dailySalesData}
                          setSalesOverview={setSalesOverview}
                          salesOverview={salesOverview}
                          getAverageOrderValue={getAverageOrderValue}
                          dashboardUpdatedAt={updatedAt}
                          refreshDataBtn={refreshData}
                          showOfflineSales={showOfflineSales}
                        />

                        <div className="mobileDetailedReports">
                          <Tabs
                            onSelect={(tabIndex) => {
                              setSelectedTabIndex(tabIndex);
                              localStorage.setItem("tabIndex", tabIndex);
                            }}
                            selectedIndex={selectedTabIndex}
                          >
                            <TabList className="mobileReports">
                              <Tab
                                onClick={() => {
                                  getProductSalesDataAllInOne();
                                }}
                              >
                                <h2>Daily Report</h2>
                              </Tab>
                              <Tab>
                                <h2>Grand Total</h2>
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <DailySalesReportMobile
                                firstDate={firstDate}
                                lastDate={lastDate}
                                GMV={GMV}
                                getOrderAmount={getOrderAmount}
                                dailySalesData={dailySalesData}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                                dashboardUpdatedAt={updatedAt}
                                refreshDataBtn={refreshData}
                                showOfflineSales={showOfflineSales}
                              />
                            </TabPanel>
                            <TabPanel>
                              <MonthlySalesOverviewMobile
                                firstDate={firstDate}
                                lastDate={lastDate}
                                GMV={GMV}
                                getOrderAmount={getOrderAmount}
                                salesOverview={salesOverview}
                                todayBtnClicked={todayBtnClicked}
                                weekClicked={weekClicked}
                                getAverageOrderValue={getAverageOrderValue}
                                showOfflineSales={showOfflineSales}
                              />
                            </TabPanel>
                          </Tabs>
                        </div>
                      </>
                    )
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="no-data"
                        src="/images/no-data.png"
                        alt="no data"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error errorStatus={error} />
      )}
    </DailySalesDataContext.Provider>
  );
}

export default Dashboard;
