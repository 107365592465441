import React from "react";
import CountUp from "react-countup";
import { vendorsList } from "../../lib/config";
import { getCurrency } from "../../utils/helper";

const MonthlySalesCardSummary = ({
  brand,
  ordersCount,
  getOrderAmount,
  getAverageOrderValue,
}) => {
  const vendor = vendorsList.find((vendor) => vendor?.VENDOR_CODE === brand);
  return (
    <div className="salesOverviewBlockParent">
      <div className={brand ? `salesOverviewBlock ${brand}Light bordered` : "ordRev"}>
        <div className="salesOverDesktopicons">
          {brand && (
            <div className="vendors-list" style={{ display: "flex", alignItems: "center"}}>
              <img src={vendor?.FAV_ICON} alt="overview icons" />
              <h6 className={`${vendor.VENDOR_CODE}`}>
                {vendor?.TITLE ? vendor?.TITLE : "Grand Total"}
              </h6>
            </div>
          )}
        </div>
        <div className="grid-container">
          <div className="grid-item">
            {" "}
            <p className="orderSpan">
              Revenue &nbsp;{" "}
              <span className="revenue ">
                <CountUp
                  start={0}
                  end={parseInt(getOrderAmount)}
                  duration={1}
                  redraw
                  formattingFn={(num) =>
                    num.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      style: "currency",
                      currency: getCurrency(vendor?.VENDOR_CODE),
                    })
                  }
                ></CountUp>
              </span>
            </p>
          </div>
          <div>
            <p className="orderSpan">Orders</p>
            <span className="revenue">{ordersCount?.toLocaleString()}</span>
          </div>
          <div>
            {" "}
            <p className="orderSpan">AOV</p>
            <span className="revenue">{getAverageOrderValue}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySalesCardSummary;
