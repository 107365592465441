import React from "react";
import { formatAmount, getCurrency } from "../../utils/helper";
import { env } from "../../lib/config";

const BrandwiseDetails = ({ brandwiseData, brandDataCharges, vendorCode }) => {
  return (
    <div
      className="userModalContainer"
      style={{
        overflowY: "auto",
      }}
    >
      <table
        style={{
          width: "100%",
          borderTop: "1px solid #e8e9ec",
        }}
        className="brandDetails"
      >
        <thead>
          <tr className="borderbottom" style={{ width: "100%" }}>
            <th style={{ width: "40%" }} className="contentLeft">
              Brands
            </th>
            <th style={{ width: "30%" }} className="contentRight">
              Product
            </th>
            <th style={{ width: "30%" }} className="contentRight">
              Revenue
            </th>
          </tr>
        </thead>
        <tbody>
          {brandwiseData &&
            brandwiseData.length > 0 &&
            brandwiseData.map((brand, index) => {
              const productAmt = brand?.productAmount;
              return (
                <tr key={index}>
                  <td className="contentLeft">{brand?.productBrand}</td>
                  <td className="contentRight">
                    {brand?.productQty?.toLocaleString("en-IN")}
                  </td>
                  <td className="contentRight">
                    {productAmt
                      ? formatAmount(productAmt, getCurrency(vendorCode))
                      : 0}
                  </td>
                </tr>
              );
            })}
          {brandDataCharges && brandDataCharges?.shippingCharges > 0 && (
            <tr>
              <td className="contentLeft">Shipping Charges</td>
              <td className="contentRight"></td>

              <td className="contentRight">
                {formatAmount(brandDataCharges?.shippingCharges, getCurrency(vendorCode))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BrandwiseDetails;
