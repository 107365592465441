import React, { useEffect, useState, useCallback, useContext } from "react";
import UserDetailModal from "../common/userDetailModal";
import DailySaleDataCardSummary from "./dailySalesDataCardSummary";
import { useEmblaCarousel } from "embla-carousel/react";
import dayjs from "dayjs";
import orderBy from "lodash.orderby";
import { DailySalesDataContext } from "../dashboard";

const viewportCss = {
  overflow: "hidden",
};
const containerCss = {
  display: "flex",
};
const slideCss = {
  position: "relative",
  minWidth: "100%",
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
      <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
    </svg>
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
      <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
    </svg>
  </button>
);

const DailySalesReportMobileSummary = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  getProductSalesData,
  dailySalesData,
  setSelectedIndex,
  selectedIndex,
  dashboardUpdatedAt,
  refreshDataBtn,
  showOfflineSales,
}) => {
  const [modalIsOpenMobile, setIsOpenMobile] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [orderDate, setOrderDate] = useState();
  const [currentSlideIndex, setCurrentSlideIndex] = useState();
  const { visibleBrands } = useContext(DailySalesDataContext);

  const openModalMobile = (device) => {
    setIsOpenMobile(true);
    setDeviceType(device);
    document.body.style.overflow = "hidden";
  };

  const closeModalMobile = () => {
    setIsOpenMobile(false);
    document.body.style.overflow = "auto";
  };

  const [emblaRef, embla] = useEmblaCarousel({
    loop: false,
    startIndex: selectedIndex,
    containScroll: "",
    selectedClass: "is-selected",
    draggableClass: "is-draggable",
    draggingClass: "is-dragging",
  });

  const customStylesMobile = {
    content: {
      top: "47%",
      left: "47%",
      right: "auto",
      bottom: "0",
      marginRight: "-47%",
      transform: "translate(-47%, 0)",
      borderTopRightRadius: "20px",
      borderTopLeftRadius: "20px",
      width: "100%",
      padding: "0",
      overflow: "hidden",
    },
  };

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const scrollPrev = useCallback(() => {
    embla && embla.scrollPrev();
  }, [embla]);
  const scrollNext = useCallback(() => {
    embla && embla.scrollNext();
  }, [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSettle = () => {
    if (!embla) return;
    if (dailySalesData) {
      localStorage.setItem(
        "localDate",
        dayjs(dailySalesData[embla.selectedScrollSnap()]?.date).format(
          "YYYY-MM-DD"
        )
      );
    }

    setSelectedIndex(embla.selectedScrollSnap());
  };

  const onSelect = () => {
    if (!embla) return;

    setOrderDate(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  };

  /* for displaying same date whenever month is changed */
  useEffect(() => {
    if (!embla) return;
    embla.reInit();

    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setCurrentSlideIndex(embla.selectedScrollSnap());
    setSelectedIndex(embla.selectedScrollSnap());
  }, [dailySalesData]);

  /* for displaying same date whenever month is changed */
  useEffect(() => {
    const localDate = localStorage.getItem("localDate");

    if (
      localDate &&
      dayjs(localDate, "YYYY-MM-DD").format("YYYY-MM-DD") === localDate
    ) {
      for (let i = 0; i < dailySalesData?.length; i++) {
        if (
          dayjs(localDate).format("D") ===
          dayjs(dailySalesData[i].date).format("D")
        ) {
          setSelectedIndex(i);
          setOrderDate(i);
          return;
        } else {
          setSelectedIndex(0);
          setOrderDate(0);
        }
      }
    }
  }, [dailySalesData]);

  useEffect(() => {
    if (!embla) return;
    if (embla) {
      localStorage.setItem(
        "localDate",
        dayjs(dailySalesData[embla.selectedScrollSnap()]?.date).format(
          "YYYY-MM-DD"
        )
      );
    }

    setScrollSnaps(embla.scrollSnapList());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());

    setOrderDate(embla.selectedScrollSnap());
    embla.on("select", onSelect);
    embla.on("settle", onSettle);
  }, [embla]);

  return (
    <div className="statsMobile">
      <h1>Daily Sales Report</h1>
      <div className="updated-at">
        <h5 className="text-muted">
          Updated at: {dashboardUpdatedAt.toLocaleTimeString("en-US")}
        </h5>
        <img
          className="refresh-icon"
          src="/images/recurring-appointment-32.png"
          alt="arrow"
          onClick={refreshDataBtn}
        />
      </div>
      {dailySalesData && dailySalesData.length > 0 && (
        <>
          <UserDetailModal
            modalIsOpenMobile={modalIsOpenMobile}
            closeModalMobile={closeModalMobile}
            firstDate={firstDate}
            lastDate={lastDate}
            deviceType={deviceType}
            orderDate={dailySalesData[selectedIndex]?.date}
            customStylesMobile={customStylesMobile}
            showOfflineSales={showOfflineSales}
            vendorCode={deviceType?.grand.vendorCode}
          />

          <div className="mobileOrderDate">
            {/* pass active slide index as array index to date */}
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            <h4>
              Order Date{" "}
              <span>
                &nbsp;
                {/* {orderDate} */}
                {dayjs(dailySalesData[orderDate]?.date).format("ddd,D MMM")}
              </span>
            </h4>
          </div>
          <div style={viewportCss} ref={emblaRef}>
            <div style={containerCss}>
              {dailySalesData.map((day, key) => {
                const daySalesData = orderBy(day?.sales, showOfflineSales ? "grand.orderCount" : "online.orderCount", "desc");
                return (
                  <React.Fragment key={key}>
                    <div
                      style={slideCss}
                      className={`statsMobileTable carousel--dot${
                        key === selectedIndex ? " is-active" : ""
                      }`}
                    >
                      <table className="reportData">
                        <thead>
                          <tr>
                            <th className="channel">
                              <h2 style={{ paddingLeft: "16px" }}>BRAND</h2>
                            </th>
                            <th className="orders">
                              <h2>ORDERS </h2>
                            </th>
                            <th className="revenue">
                              <h2>REVENUE </h2>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {daySalesData &&
                            daySalesData.map((sales, index) => {
                              return (
                                visibleBrands?.includes(sales.grand?.vendorCode) && (
                                  <DailySaleDataCardSummary
                                    key={index}
                                    vendorCode={sales.grand?.vendorCode}
                                    ordersCount={
                                      showOfflineSales
                                        ? sales?.grand
                                          ? sales?.grand?.orderCount
                                          : "-"
                                        : sales?.online
                                        ? sales?.online?.orderCount
                                        : "-"
                                    }
                                    getOrderAmount={
                                      showOfflineSales
                                        ? sales?.grand
                                          ? getOrderAmount(sales?.grand, GMV)
                                          : "-"
                                        : sales?.online
                                        ? getOrderAmount(sales?.online, GMV)
                                        : "-"
                                    }
                                    onClick={() =>
                                      sales["grand"]
                                        ? openModalMobile(sales)
                                        : false
                                    }
                                    currentSlideIndex={orderDate}
                                    index={key}
                                    missingOnlineSalesObject={sales?.online}
                                    raiseAlert={
                                      showOfflineSales
                                        ? sales?.grand?.raiseAlert
                                        : sales?.online?.raiseAlert
                                    }
                                  />
                                )
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DailySalesReportMobileSummary;
