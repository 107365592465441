import React from "react";
import CountUp from "react-countup";
import { env } from "../../lib/config";
import { getCurrency } from "../../utils/helper";

const MonthlySalesCard = ({
  deviceType,
  ordersCount,
  getOrderAmount,
  getAverageOrderValue,
  orderAmountStatus,
  orderCountStatus,
}) => {
  const deviceTypeObj = {
    website: "Web",
    mobileWebsite: "Mobile Website",
    android: "Android",
    iOS: "iOS",
    adminPanel: "Corporate",
    online: "Online Sales",
    pos: "Offline Sales",
    seller_app: "ONDC"
  };
  return (
    <div className="salesOverviewBlockParent">
      <div
        className={deviceType ? `salesOverviewBlock ${deviceType}` : "ordRev"}
      >
        <div className="salesOverDesktopicons">
          {deviceType && (
            <>
              <img
                src={`images/${deviceType.toLowerCase()}.svg`}
                alt="overview icons"
              />
              <h6>
                {deviceTypeObj[deviceType]
                  ? deviceTypeObj[deviceType]
                  : "Grand Total"}
              </h6>
            </>
          )}
        </div>
        <div className="grid-container">
          <div className="grid-item">
            {" "}
            <p className="orderSpan">
              Revenue &nbsp;{" "}</p>
              <div className="monthlySalesArrows">
                <span
                  style={{ marginTop: "10px" }}
                  className={`${orderAmountStatus}`}
                ></span>
                <span className="revenue">
                  <CountUp
                    start={0}
                    end={parseInt(getOrderAmount)}
                    duration={1}
                    formattingFn={(num) =>
                      num.toLocaleString("en-IN", {
                        minimumFractionDigits: 0,
                        style: "currency",
                        currency: getCurrency(env.VENDOR_CODE),
                      })
                    }
                  ></CountUp>
                </span>
              </div>
          </div>
          <div>
            <p className="orderSpan">Orders</p>
            <div className="monthlySalesArrows">
              <span
                style={{ marginTop: "10px" }}
                className={`${orderCountStatus}`}
              ></span>
              <span className="revenue">{ordersCount?.toLocaleString()}</span>
            </div>
          </div>
          <div>
            {" "}
            <p className="orderSpan">AOV</p>
            <span className="revenue">{getAverageOrderValue}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySalesCard;
