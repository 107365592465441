import React, { useEffect } from "react";

function Error({ errorStatus }) {

  /* redirect to the login after displaying error for 3 seconds */
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, []);

  return (
    <main className="min-h-screen flex flex-col bg-white">
      <div
        style={{ textAlign: "center", margin: "50px 0" }}
        className="error-404"
      >
        <div className="flex justify-center">
          <img
            style={{ width: "auto" }}
            alt="error-400"
            src="/images/error.png"
          />
        </div>
        <h1
          style={{ fontSize: "32px", margin: "24px 0 13px", fontWeight: "500" }}
        >
          Access Restricted
        </h1>
        {/* <p style={{ fontSize: "19px", margin: " 0 0 40px" }}>
          {errorStatus?.message}
        </p> */}
        {/* <span>Error: {errorStatus.code}</span> */}
      </div>
    </main>
  );
}

export default Error;
