export const deviceTypes = [
  {
    id: 1,
    key: "website",
    deviceName: "Web",
    colorCode: "#e3e5f4",
    hiddenFor: ["ggg"],
  },
  {
    id: 2,
    key: "mobileWebsite",
    deviceName: "Mobile",
    colorCode: "#e1e9f4",
    hiddenFor: ["ggg"],
  },
  {
    id: 3,
    key: "android",
    deviceName: "Android",
    colorCode: "#e0f2f6",
    hiddenFor: ["ggg"],
  },
  {
    id: 4,
    key: "iOS",
    deviceName: "iOS",
    colorCode: "#e4e1f4",
    hiddenFor: ["ggg"],
  },
  {
    id: 5,
    key: "adminPanel",
    deviceName: "Corporate",
    colorCode: "#ede0f1",
    hiddenFor: ["ggg"],
  },
  {
    id: 6,
    key: "online",
    deviceName: "Online Sales",
    colorCode: "#e0e9f2",
    hiddenFor: ["ggg"],
  },
  {
    id: 7,
    key: "pos",
    deviceName: "Offline Sales",
    colorCode: "#e2f3e3",
    hiddenFor: ["stb", "bbc", "tmc", "orh", "srn", "ggg"],
  },
  {
    id: 8,
    key: "seller_app",
    deviceName: "ONDC",
    colorCode: "#ebf4fd",
  },
  {
    id: 9,
    key: "grand",
    deviceName: "Total",
    colorCode: "#ffe4e4",
  },
];
