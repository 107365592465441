import React from "react"
import Modal from "react-modal"
import { vendorsList } from "../../lib/config";
import {checkIfConfigExists } from "../../utils/helper"

const VendorSelectorModal = ({
  modalIsOpenVendor,
  closeVendorModal,
  customStyles,
}) => {
  // check if config exists for allowed vendor codes received from backend
  const vendorsWithConfig = checkIfConfigExists(
    JSON.parse(localStorage.getItem("allowedVendorCodes"))
  );

  // build list items for dropdown menu with logo, title & good glamm report option
  let dropDownList = vendorsList.filter((brand) => {
    if (
      brand.VENDOR_CODE === "ggg" ||
      vendorsWithConfig.includes(brand.VENDOR_CODE)
    ) {
      return brand;
    }
  });
  return (
    <div>
      <Modal
        isOpen={modalIsOpenVendor}
        onRequestClose={closeVendorModal}
        contentLabel="Vendor Selector Modal"
        ariaHideApp={false}
        className="vendorDropDown"
        style={customStyles}
      >
        <span
          className="close"
          style={{
            textAlign: "right",
            float: "right",
          }}
        >
          <img
            onClick={closeVendorModal}
            src="/images/mobile-cancel.svg"
            alt="cancel"
            width="12px"
            height="12px"
            style={{
              cursor: "pointer"
            }}
          />
        </span>
        <ul className="domains-list">
          {dropDownList.map((vendor, index) => {
            return (
              <li key={index}>
                <a href={vendor.domain}>
                  <img src={vendor.FAV_ICON} alt="logo" />
                  <p>{vendor.TITLE}</p>
                </a>
              </li>
            );
          })}
        </ul>
      </Modal>
    </div>
  );
};

export default VendorSelectorModal