import React, {useRef} from "react";
import ReactSelect, { components } from "react-select";
import Checkbox from "./checkbox";


export const MultiSelect = props => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All Brands",
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];
  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value)
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  /* Override default with Custom UI */
  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: "auto",
      background: "#ff9797",
      borderRadius: "4px",
      fontSize: "11px",
      padding: "3px",
      color: "white",
      order: 99,
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+${length}`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 3;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <Checkbox
          label={props.label}
          isChecked={props.isSelected}
          handleToggle={() => null}
          id={props.value}
        />
      </components.Option>
    </div>
  );

  return (
    <ReactSelect
      isClearable={false}
      classNamePrefix="react-select"
      components={{ Option, MultiValue }}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "white",
        },
      })}
    />
  );
};

