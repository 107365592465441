import React, { useState, useEffect, useContext } from "react";
import { getCurrency, shadeHexColor } from "../utils/helper";
import CountUp from "react-countup";
import { Tooltip } from "@material-ui/core";
import { DailySalesDataContext } from "./dashboard";

const SalesOverviewTableSummary = ({
  GMV,
  getOrderAmount,
  salesOverview,
  getAverageOrderValue,
  animated,
  setAnimated,
}) => {
  const [sortedVendordCodes, setSortedVendorCodes] = useState([]);

  const { visibleBrands } = useContext(DailySalesDataContext);

  useEffect(() => {
    if (salesOverview) {
      const brands = Object.keys(salesOverview);
      const filteredBrands = brands?.filter(
        (brand) =>
          salesOverview[brand]?.orderCount > 0 && visibleBrands?.includes(brand)
      );
      setSortedVendorCodes(
        filteredBrands?.sort(function (a, b) {
          return (
            salesOverview[b]?.payableAmount - salesOverview[a]?.payableAmount
          );
        })
      );
    }
  }, [salesOverview, visibleBrands]);

  return (
    <>
      <td
        className="text-center"
        style={{
          backgroundColor: shadeHexColor("#D3D3D3", 0.2),
          width: 150,
          background: "#D3D3D3",
          position: "sticky",
          left: 0,
        }}
      >
        Grand Total
      </td>
      {salesOverview &&
        sortedVendordCodes?.length > 0 &&
        sortedVendordCodes.map((brand, key) => {
          return (
            <React.Fragment key={key}>
              <td
                className="text-right select-orders"
                style={{
                  backgroundColor: shadeHexColor("#f2f3f7", 0.3),
                }}
              >
                <span className={`font-bold `} style={{ width: "70px" }}>
                  {salesOverview[brand]?.orderCount > 0 ? (
                    <Tooltip
                      title={`AOV : ${getAverageOrderValue(
                        salesOverview[brand],
                        GMV,
                        brand
                      )}`}
                      place="bottom"
                      arrow
                    >
                      <span>
                        {salesOverview[brand]?.orderCount?.toLocaleString(
                          "en-IN"
                        )}
                      </span>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </span>
              </td>

              <td
                style={{
                  backgroundColor: shadeHexColor("#f2f3f7", 0.3),
                }}
                className={`text-right }`}
              >
                {salesOverview[brand]?.payableAmount > 0 ? (
                  <>
                    {!animated ? (
                      <CountUp
                        start={0}
                        end={parseInt(
                          getOrderAmount(salesOverview[brand], GMV)
                        )}
                        duration={1}
                        startOnMount={false}
                        onEnd={() => setAnimated(true)}
                        formattingFn={(num) =>
                          num.toLocaleString("en-IN", {
                            minimumFractionDigits: 0,
                            style: "currency",
                            currency: getCurrency(brand),
                          })
                        }
                      ></CountUp>
                    ) : (
                      parseInt(
                        getOrderAmount(salesOverview[brand], GMV)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 0,
                        style: "currency",
                        currency: getCurrency(brand),
                      })
                    )}
                  </>
                ) : (
                  "-"
                )}
              </td>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default SalesOverviewTableSummary;
