import axios from "axios";
import { env } from "./config";
const isServer = () => typeof window === `undefined`;
class MyglammAPI {
  HEADERS;
  myGlammV2;
  static HEADERS = {
    "Content-Type": "application/json",
  };
   apiurl = env.apiurl || "https://mag.myglamm.net";
  static APIVendor = env.VENDOR_CODE || "mgp";

  constructor() {
    this.myGlammV2 = axios.create({
      baseURL: this.apiurl,
      headers: this.HEADERS,
    });

 
    // Add a request interceptor
    this.myGlammV2.interceptors.request.use(
      function (config) {
        const API_KEY = env.API_KEY || "edfecef6ff6cc50f39eff3fed437533f";

        let xaTkn;
        if (!isServer()) {
          xaTkn = localStorage.getItem("xaTkn");
        }

        let { headers } = config;
        if (xaTkn) {
          headers = { ...headers, "x-access-token": xaTkn };
        } else {
          headers = { ...headers, apikey: API_KEY };
        }

        return {
          ...config,
          headers,
        };
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.myGlammV2.interceptors.response.use(undefined, (error) => {
      if (error.config && error.response && error.response.status === 401) {
        localStorage.removeItem("xaTkn");
      }
      if (error.config && error.response && error.response.status === 403) {
        localStorage.removeItem("xaTkn");
      }
      if (error.config && error.response && error.response.status === 498) {
        const config = { ...error.config };
        config.headers["x-access-token"] = error.response.data.newToken;
        localStorage.setItem("xaTkn", error.response.data.newToken);
        return this.myGlammV2.request(config);
      }
      return Promise.reject(error);
    });
  }

  /* get day wise data [product sales] */
  getProductSalesData(firstDate, lastDate) {
    return this.myGlammV2.get(
      `/mis-ms/product-sales-data/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}`
    );
  }

  /* get grand total overview */
  getSalesGrandTotal(firstDate, lastDate) {
    return this.myGlammV2.get(
      `/mis-ms/product-sales-data-grand-total/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}`
      // "/mis-ms/product-sales-data-grand-total/2020-04-01/2020-04-30?vendorCode=mgp&bgInclude=website"
    );
  }

  getUserDetailsData(firstDate, lastDate, deviceType) {
    return this.myGlammV2.get(
      `/mis-ms/user-details/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}&deviceType=${deviceType}`
    );
  }

  /* get brandwise data according to first date,last date and devicetype */
  getBrandWiseData(firstDate, lastDate, deviceType) {
    return this.myGlammV2.get(
      `/mis-ms/brand-details/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}&deviceType=${deviceType}`
    );
  }

  /* download sales data with GMV calculated */
  downloadSalesDataGMV(firstDate, lastDate, downloadParam) {
    return this.myGlammV2.get(
      `/mis-ms/product-sales-data-download-csv/${downloadParam}/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}`,
      { responseType: "arraybuffer" }
    );
  }

  soicalLogin(payload) {
    return this.myGlammV2.post(`/nucleusGoogleLogin`, payload);
  }
  /* get day wise data [product sales] and overall grand sales data in one */
  getProductSalesDataAllInOne(firstDate, lastDate) {
    return this.myGlammV2.get(
      `/mis-ms/product-sales-data-all-in-one-new/${firstDate}/${lastDate}?vendorCode=${MyglammAPI.APIVendor}`
    );
  }
}

export default MyglammAPI;
