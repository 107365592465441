import React from "react"
import '../../styles/checkbox.scss'

const Checkbox = ({ label,isChecked, handleToggle, id }) => {
    return (
      <>
        <label className="custom-checkbox-label" htmlFor={id}>
          {label}
          <input
            checked={isChecked}
            className="custom-checkbox"
            id={id}
            type="checkbox"
            onChange={handleToggle}
          />
          <span className={`custom-checkbox-span`} />
        </label>
      </>
    );
};

export default Checkbox;
