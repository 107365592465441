import React, { useContext, useEffect, useState } from "react";
import { DailySalesDataContext } from "../dashboard";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MyGlammAPI from "../../lib/MyglammAPI";
import BrandwiseDetails from "./brandwiseDetails";
import dayjs from "dayjs";
import UserDetails from "./userDetails";
import { vendorsList } from "../../lib/config";

const UserDetailModal = ({
  modalIsOpenMobile,
  closeModalMobile,
  deviceType,
  orderDate,
  customStylesMobile,
  showOfflineSales,
  vendorCode
}) => {
  const [userOverview, setUserOverview] = useState();
  const [brandwiseData, setBrandwiseData] = useState();
  const [brandDataCharges, setBrandDataCharges] = useState();
  const [loader, setLoader] = useState(false);
  const myglammApi = new MyGlammAPI();
  const { dailySalesData } = useContext(DailySalesDataContext) || {};
  const [summarizedReport, setSummarizedReport] = useState();
  const vendor = vendorsList.find(vendor => vendor.VENDOR_CODE === deviceType?.grand?.vendorCode);

  orderDate = dayjs(orderDate).format("YYYY-MM-DD");

  const getSelectedDateData = (dailySalesData, orderDate, type) => {
    // convert currently selected order date to timestamp for date comparison
    const orderTimestamp = new Date(orderDate).getTime();
    const selectedDateOrderData = dailySalesData?.filter(
      (e) => e.date?.getTime() === orderTimestamp
    );

    selectedDateOrderData[0].sales?.length ? setSummarizedReport(true) : setSummarizedReport(false);
    
    if (type === "userOverview") {
      return summarizedReport ?
        showOfflineSales ?
          deviceType?.grand : deviceType?.online
        :
        selectedDateOrderData[0]?.sales?.[deviceType?.key];
    } else if (type === "brandWise") {
      return summarizedReport ?
        showOfflineSales ?
          deviceType.grand?.brandwiseData : deviceType.online?.brandwiseData
        : selectedDateOrderData[0].sales?.[deviceType?.key].brandwiseData;
    } else {
      return selectedDateOrderData;
    }
  };

  const getUserData = () => {
    if (dailySalesData) {
      // use context to set user data
      setUserOverview(
        getSelectedDateData(dailySalesData, orderDate, "userOverview")
      );
    } else {
      //fallback on old API
      myglammApi
        .getUserDetailsData(orderDate, orderDate, deviceType?.key)
        .then(({ data }) => {
          setLoader(false);
          setUserOverview(data.data);
        })
        .catch((err) => {
          setLoader(false);
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (orderDate && modalIsOpenMobile) {
      getUserData();
    }
  }, [orderDate, deviceType, modalIsOpenMobile, summarizedReport]);

  const getBrandWiseData = () => {
    if (dailySalesData) {
      // use context to set brand data
      let brandData = getSelectedDateData(
        dailySalesData,
        orderDate,
        "brandWise"
      );

      if(brandData) {
        setBrandDataCharges(brandData);
        brandData = Object.values(brandData);
        brandData = brandData.sort((a, b) => b.productAmount - a.productAmount);
        setBrandwiseData(brandData);
      }
    } else {
      //fallback on old API
      myglammApi
        .getBrandWiseData(orderDate, orderDate, deviceType?.key)
        .then(({ data }) => {
          let brandData = data.data.result;
          setBrandDataCharges(brandData);
          delete brandData.shippingCharges;
          brandData = Object.values(brandData.brandwiseData);
          brandData = brandData.sort(
            (a, b) => b.productAmount - a.productAmount
          );
          setBrandwiseData(brandData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpenMobile}
      onRequestClose={closeModalMobile}
      style={customStylesMobile}
      contentLabel="Stats Modal Mobile"
      ariaHideApp={false}
    >
      <div className="modalStatsMobile">
        <div>
          {" "}
          <span
            className="close"
            style={{
              textAlign: "right",
              float: "right",
            }}
          >
            <img
              onClick={closeModalMobile}
              src="/images/mobile-cancel.svg"
              alt="cancel"
              width="15px"
              height="15px"
            />
          </span>
          <h1>
            {dayjs(orderDate).format("ddd,D MMM YYYY")}&nbsp;&nbsp;
            {summarizedReport ? vendor?.TITLE : deviceType?.deviceName}
          </h1>
        </div>

        <Tabs>
          <TabList style={{ marginBottom: "20px" }}>
            <Tab onClick={getUserData}>
              <h2>Customer Details</h2>
            </Tab>
            <Tab onClick={getBrandWiseData}>
              <h2>Brand Wise Details</h2>
            </Tab>
          </TabList>
          <TabPanel>
            <UserDetails userOverview={userOverview} />
          </TabPanel>
          <TabPanel>
            <BrandwiseDetails
              brandwiseData={brandwiseData}
              brandDataCharges={brandDataCharges}
              vendorCode={vendorCode}
            />
          </TabPanel>
        </Tabs>
      </div>
    </Modal>
  );
};

export default UserDetailModal;
