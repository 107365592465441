import React, { useState } from "react";
import "./App.scss";
import "./styles/tabs.scss";
import Dashboard from "./container/dashboard";
import MyGlammAPI from "../src/lib/MyglammAPI";
import { GoogleLogin } from "react-google-login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NProgress from "nprogress";
import { env } from "./lib/config";
import { config } from "react-transition-group";
import { Helmet } from 'react-helmet';


config.disabled = true;

function App() {
  const myglammApi = new MyGlammAPI();
  const googleToken = env.GOOGLE_TOKEN;
  const xaTkn = localStorage.getItem("xaTkn");
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState();

  const responseGoogle = (response) => {
    const payload = {
      id_token: response.tokenId,
    };
    if (response.tokenId) {
      NProgress.start();
      myglammApi
        .soicalLogin(payload)
        .then(({ data: socialInfo }) => {
          localStorage.setItem("xaTkn", socialInfo.data.token);
          localStorage.setItem("userId", socialInfo.data.userId);
          localStorage.setItem("allowedVendorCodes", JSON.stringify(socialInfo.data?.allowedVendorCodes));
          setUserData(socialInfo.data.userId);
          NProgress.done();
        })
        .catch((err) => {
          NProgress.done();
          toast.error(err?.response?.data.message);
        });
    }
  };
  

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=no"
        />

        <title>{`MIS Report | ${env.TITLE}`}</title>
        <link id="favicon" rel="icon" href={`${env.FAV_ICON}`} />
      </Helmet>

      <ToastContainer
        position="top-right"
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <div className="row">
        {xaTkn && xaTkn !== "undefined" && userId ? (
          <Dashboard />
        ) : (
          <div className="login">
            <img
              className="myglamm-logo text-center"
              src={env.IMAGE_URL}
              alt="logo"
            />

            <h1>{`${env.TITLE} MIS Report`}</h1>

            <GoogleLogin
              clientId={googleToken}
              // buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <button
                  className="col-md-6 btn-google"
                  type="button"
                  onClick={renderProps.onClick}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <img width="19" src="/images/googleLogin.svg" alt="google" />
                  Login with Google
                </button>
              )}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default App;
