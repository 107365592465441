import React, { Fragment } from "react";
import MonthlySalesCard from "./monthlySalesCard";
import CountUp from "react-countup";
import { env } from "../../lib/config";
import { getCurrency } from "../../utils/helper";

const MonthlySalesOverviewMobile = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  salesOverview,
  todayBtnClicked,
  weekClicked,
  getAverageOrderValue,
  showOfflineSales
}) => {

  let periodical = "Monthly";
  if (todayBtnClicked) {
    periodical = "Daily";
  } else if (weekClicked) {
    periodical = "Weekly";
  }

  return (
    <Fragment>
      <div className="mobileSalesTitle">
        <h1>{periodical} Sales Overview</h1>
      </div>
      {salesOverview?.grand && (
        <div className="salesOverviewFigures desktop-only">
          <h5>{periodical} Sales Overview</h5>
          <p>
            ( Orders&nbsp;
            <span style={{ marginRight: "20px" }}>
              {salesOverview.grand?.orderCount}{" "}
            </span>
            Revenue
            <span style={{ marginLeft: "5px" }}>
              <CountUp
                start={0}
                end={parseInt(getOrderAmount(salesOverview.grand, GMV))}
                duration={1}
                formattingFn={(num) =>
                  num.toLocaleString("en-IN", {
                    minimumFractionDigits: 0,
                    style: "currency",
                    currency: getCurrency(env.VENDOR_CODE),
                  })
                }
              ></CountUp>
            </span>
            &nbsp; )
          </p>
        </div>
      )}
      <div className="salesOverviewMobile">
        {salesOverview?.grand && (
          <MonthlySalesCard
            ordersCount={
              showOfflineSales
                ? salesOverview.grand?.orderCount
                : salesOverview.online?.orderCount
            }
            getOrderAmount={
              showOfflineSales
                ? getOrderAmount(salesOverview.grand, GMV)
                : getOrderAmount(salesOverview.online, GMV)
            }
            getAverageOrderValue={
              showOfflineSales
                ? getAverageOrderValue(
                    salesOverview.grand,
                    GMV,
                    env.VENDOR_CODE
                  )
                : getAverageOrderValue(
                    salesOverview.online,
                    GMV,
                    env.VENDOR_CODE
                  )
            }
            deviceType="grand"
            orderAmountStatus={
              showOfflineSales
                ? salesOverview.grand?.orderAmountStatus
                : salesOverview.online?.orderAmountStatus
            }
            orderCountStatus={
              showOfflineSales
                ? salesOverview.grand?.orderCountStatus
                : salesOverview.online?.orderCountStatus
            }
          />
        )}
        {salesOverview?.website && (
          <MonthlySalesCard
            deviceType="website"
            ordersCount={salesOverview.website?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.website, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.website,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.website?.orderAmountStatus}
            orderCountStatus={salesOverview?.website?.orderCountStatus}
          />
        )}
        {salesOverview?.mobileWebsite && (
          <MonthlySalesCard
            deviceType="mobileWebsite"
            ordersCount={salesOverview.mobileWebsite?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.mobileWebsite, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.mobileWebsite,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.mobileWebsite?.orderAmountStatus}
            orderCountStatus={salesOverview?.mobileWebsite?.orderCountStatus}
          />
        )}
        {salesOverview?.android && (
          <MonthlySalesCard
            deviceType="android"
            ordersCount={salesOverview.android?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.android, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.android,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.android?.orderAmountStatus}
            orderCountStatus={salesOverview?.android?.orderCountStatus}
          />
        )}
        {salesOverview?.iOS && (
          <MonthlySalesCard
            deviceType="iOS"
            ordersCount={salesOverview.iOS?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.iOS, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.iOS,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.iOS?.orderAmountStatus}
            orderCountStatus={salesOverview?.iOS?.orderCountStatus}
          />
        )}
        {salesOverview?.adminPanel && (
          <MonthlySalesCard
            deviceType="adminPanel"
            ordersCount={salesOverview.adminPanel?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.adminPanel, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.adminPanel,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.adminPanel?.orderAmountStatus}
            orderCountStatus={salesOverview?.adminPanel?.orderCountStatus}
          />
        )}
        {salesOverview?.online && (
          <MonthlySalesCard
            deviceType="online"
            ordersCount={salesOverview.online?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.online, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.online,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.online?.orderAmountStatus}
            orderCountStatus={salesOverview?.online?.orderCountStatus}
          />
        )}
        {salesOverview?.seller_app && (
          <MonthlySalesCard
            deviceType="seller_app"
            ordersCount={salesOverview?.seller_app?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview?.seller_app, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview?.seller_app,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.seller_app?.orderAmountStatus}
            orderCountStatus={salesOverview?.seller_app?.orderCountStatus}
          />
        )}
        {showOfflineSales && salesOverview?.pos && (
          <MonthlySalesCard
            deviceType="pos"
            ordersCount={salesOverview.pos?.orderCount}
            getOrderAmount={getOrderAmount(salesOverview.pos, GMV)}
            getAverageOrderValue={getAverageOrderValue(
              salesOverview.pos,
              GMV,
              env.VENDOR_CODE
            )}
            orderAmountStatus={salesOverview?.pos?.orderAmountStatus}
            orderCountStatus={salesOverview?.pos?.orderCountStatus}
          />
        )}
      </div>
    </Fragment>
  );
};

export default MonthlySalesOverviewMobile;
