import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Checkbox from "./checkbox";
import { DailySalesDataContext } from "../dashboard";
import { env, vendorsList } from "../../lib/config";
import { MultiSelect } from "./multiSelect";
const FilterModal = ({
  modalIsOpenFilter,
  closeFilterModal,
  customStyles,
}) => {
  const { showOfflineSales, setShowOfflineSales, GMV, setGMV, visibleBrands, allBrands, setVisibleBrands } = useContext(
    DailySalesDataContext
  );

  const [NET, setNET] = useState();
  const [offline, setOffline] = useState();

  const getOptionsForDropDown = (brands) => {
    return brands?.map((element) => {
      const vendorName = vendorsList?.filter(
        (brand) => brand?.VENDOR_CODE === element
      )[0];
      return { value: element, label: vendorName?.TITLE };
    })
  };

  const customizedBrandObjectForDropDownOptions =
    getOptionsForDropDown(allBrands);

  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleChange = (values) => {
    setSelectedBrands(values);
  };

  const selectedVals = selectedBrands?.map((x) => x?.value);
  
  const handleOffline = () => {
    setOffline(!offline);
  }

  const handleNETAmount = () => {
    setNET(!NET);
  }

  const applyFilter = () => {
    setGMV(!NET);
    localStorage.setItem("GMV", !NET);
    
    setShowOfflineSales(offline);
    localStorage.setItem("showOfflineSales", offline);

    setVisibleBrands(selectedVals);
    localStorage.setItem("visibleBrands", JSON.stringify(selectedVals));

    closeFilterModal();
  }

  const handleCancel = () => {
    setNET(!GMV);
    setOffline(showOfflineSales);
    closeFilterModal();
  }

  const resetFilters = () => {
    setOffline(false);
    setNET(false);
  }

  useEffect(() => {
    setNET(!GMV);
    setOffline(showOfflineSales);
  }, [GMV, showOfflineSales]);

  useEffect(() => {
    setSelectedBrands(getOptionsForDropDown(visibleBrands));
  }, [visibleBrands])
  
  return (
    <Modal
      isOpen={modalIsOpenFilter}
      onRequestClose={closeFilterModal}
      contentLabel="Filters"
      ariaHideApp={false}
      className="filterModal"
      style={customStyles}
    >
      <div className="filterModalContent">
        <div className="filterModalTitle">
          <span
            className="arrow-left"
            onClick={handleCancel}
            alt="back"
            style={{
              backgroundImage: "url(/images/left-arrow.svg)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <h1>Filter</h1>
          <h4 onClick={resetFilters}>Reset</h4>
        </div>

        <div className="filterHead">Amount</div>
        <div className="filters-list">
          <div className="filter-item">
            <Checkbox
              label="Include offline sales in grand total"
              isChecked={offline}
              handleToggle={handleOffline}
              id="include-offline-sales"
            />
          </div>
          <div className="filter-item">
            <Checkbox
              label="Display NET Amount"
              isChecked={NET}
              handleToggle={handleNETAmount}
              id="display-net-amount"
            />
          </div>
        </div>
        {env.VENDOR_CODE === "ggg" && (
          <>
            <div className="filterHead">Brands</div>
            <div className="filter-list">
              <div className="filter-item">
                <MultiSelect
                  options={customizedBrandObjectForDropDownOptions}
                  value={selectedBrands}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="actions-wrapper">
          <div className="filterActions">
            <button
              type="button"
              onClick={handleCancel}
              className="btn cancel-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={applyFilter}
              className="btn apply-btn"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal