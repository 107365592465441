import React, { useState, useEffect } from "react";
import UserDetailModal from "./common/userDetailModal";
import dayjs from "dayjs";
import { deviceTypes } from "../utils/getDeviceTypes";
import SalesOverviewTable from "./salesOverviewTable";
import { formatAmount, getCurrency } from "../utils/helper";
import { env } from "../lib/config";
import { TableVirtuoso } from "react-virtuoso";
import { Tooltip } from "@material-ui/core";

const DailySalesReport = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  dailySalesData,
  salesOverview,
  setSalesOverview,
  getAverageOrderValue,
  dashboardUpdatedAt,
  refreshDataBtn,
  showOfflineSales,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [orderDate, setOrderDate] = useState();

  const filteredDeviceTypes = showOfflineSales
    ? deviceTypes
    : deviceTypes.filter((device) => device.key !== "pos");

  const openModal = (device, date) => {
    setIsOpen(true);
    setDeviceType(device);
    setOrderDate(date);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      minHeight: "200px",
    },
    overlay: { zIndex: 1000 },
  };

  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    setAnimated(false);
  }, [salesOverview, GMV]);

  return (
    <div className="stats desktop-only">
      <h2>Daily Sales Report</h2>
      <div className="updated-at">
        <h5 className="text-muted">
          Updated at: {dashboardUpdatedAt?.toLocaleTimeString("en-US")}
        </h5>
        <img
          className="refresh-icon"
          src="/images/recurring-appointment-32.png"
          alt="arrow"
          onClick={refreshDataBtn}
        />
      </div>
      <UserDetailModal
        modalIsOpenMobile={modalIsOpen}
        closeModalMobile={closeModal}
        firstDate={firstDate}
        lastDate={lastDate}
        deviceType={deviceType}
        orderDate={orderDate}
        customStylesMobile={customStyles}
        vendorCode={env.VENDOR_CODE}
      />
      {dailySalesData && (
        <TableVirtuoso
          style={{ minHeight: "90vh" }}
          totalCount={dailySalesData.length + 1}
          components={{
            Table: ({ style, ...props }) => (
              <table {...props} style={{ ...style }} />
            ),
          }}
          fixedHeaderContent={() => (
            <>
              <tr className="thWhite">
                <th
                  className="channel"
                  style={{
                    width: 150,
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                  }}
                >
                  Channel
                </th>
                {filteredDeviceTypes &&
                  filteredDeviceTypes.length > 0 &&
                  filteredDeviceTypes.map((device, key) => {
                    return (
                      !device.hiddenFor?.includes(env.VENDOR_CODE) && (
                        <React.Fragment key={key}>
                          <th
                            colSpan="2"
                            className={
                              device && device.key === "grand"
                                ? "totalAmt"
                                : `${device.key}Dark`
                            }
                          >
                            {device.key !== "grand" ? (
                              <>
                                <img
                                  src={`images/${device.deviceName
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}-white.svg`}
                                  alt={device.deviceName}
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {device.deviceName}
                          </th>
                        </React.Fragment>
                      )
                    );
                  })}
              </tr>

              <tr className="OrdRev text-right">
                <th
                  className="channelLight"
                  style={{
                    width: 150,
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Date
                </th>
                {filteredDeviceTypes &&
                  filteredDeviceTypes.map(
                    (device, key) =>
                      !device.hiddenFor?.includes(env.VENDOR_CODE) && (
                        <React.Fragment key={key}>
                          <th
                            colSpan="1"
                            className={`text-right ${
                              device.key !== "grand"
                                ? `${device.key}Light`
                                : "totalAmtLight"
                            }`}
                          >
                            <span className="text-right">Orders</span>
                          </th>
                          <th
                            colSpan="1"
                            className={`text-right ${
                              device.key !== "grand"
                                ? `${device.key}Light`
                                : "totalAmtLight"
                            }`}
                            style={{
                              paddingLeft: "60px",
                              paddingRight: "20px",
                            }}
                          >
                            <span className="text-right">Revenue</span>
                          </th>
                        </React.Fragment>
                      )
                  )}
              </tr>
            </>
          )}
          itemContent={(index) => {
            return index === 0 ? (
              <SalesOverviewTable
                firstDate={firstDate}
                lastDate={lastDate}
                GMV={GMV}
                getOrderAmount={getOrderAmount}
                dailySalesData={dailySalesData}
                setSalesOverview={setSalesOverview}
                salesOverview={salesOverview}
                getAverageOrderValue={getAverageOrderValue}
                showOfflineSales={showOfflineSales}
                animated={animated}
                setAnimated={setAnimated}
              />
            ) : (
              <>
                <td
                  className="text-center"
                  style={{
                    width: 150,
                    background: "#D3D3D3",
                    position: "sticky",
                    left: 0,
                  }}
                >
                  {dayjs(dailySalesData[index - 1]?.date).format("ddd,D MMM")}
                </td>
                {filteredDeviceTypes &&
                  filteredDeviceTypes.map((device, key) => {
                    const deviceSales = dailySalesData[index - 1]?.sales;

                    return (
                      !device.hiddenFor?.includes(env.VENDOR_CODE) && (
                        <React.Fragment key={key}>
                          <td
                            className={`${
                              deviceSales[device?.key] ? "select-orders" : ""
                            }`}
                            style={{
                              backgroundColor: device.colorCode,
                              textAlign: "right",
                            }}
                            onClick={
                              device?.key === "grand"
                                ? showOfflineSales
                                  ? () =>
                                      deviceSales["grand"]
                                        ? openModal(
                                            deviceTypes.find(
                                              (device) => device.key === "grand"
                                            ),
                                            dailySalesData[index - 1]?.date
                                          )
                                        : false
                                  : () =>
                                      deviceSales["online"]
                                        ? openModal(
                                            deviceTypes.find(
                                              (device) =>
                                                device.key === "online"
                                            ),
                                            dailySalesData[index - 1]?.date
                                          )
                                        : false
                                : () =>
                                    deviceSales[device?.key]
                                      ? openModal(
                                          device,
                                          dailySalesData[index - 1]?.date
                                        )
                                      : false
                            }
                          >
                            <span
                              className={`text-right font-bold ${
                                deviceSales[device?.key]?.deviceType === "grand"
                                  ? "font-bold"
                                  : ""
                              } ${
                                deviceSales[device?.key] ? "orderCount" : ""
                              }`}
                            >
                              {device.key === "grand" ? (
                                showOfflineSales ? (
                                  deviceSales["grand"]?.orderCount > 0 ? (
                                    <Tooltip
                                      title={`AOV: ${getAverageOrderValue(
                                        deviceSales["grand"],
                                        GMV,
                                        env.VENDOR_CODE
                                      )}`}
                                      place="bottom"
                                      arrow
                                    >
                                      <span>
                                        {deviceSales[
                                          "grand"
                                        ]?.orderCount?.toLocaleString("en-IN")}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )
                                ) : deviceSales["online"]?.orderCount > 0 ? (
                                  <Tooltip
                                    title={`AOV: ${getAverageOrderValue(
                                      deviceSales["online"],
                                      GMV,
                                      env.VENDOR_CODE
                                    )}`}
                                    place="bottom"
                                    arrow
                                  >
                                    <span>
                                      {deviceSales[
                                        "online"
                                      ]?.orderCount?.toLocaleString("en-IN")}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "-"
                                )
                              ) : deviceSales[device?.key]?.orderCount > 0 ? (
                                <Tooltip
                                  title={`AOV: ${getAverageOrderValue(
                                    deviceSales[device?.key],
                                    GMV,
                                    env.VENDOR_CODE
                                  )}`}
                                  place="bottom"
                                  arrow
                                >
                                  <span>
                                    {deviceSales[
                                      device.key
                                    ]?.orderCount?.toLocaleString("en-IN")}
                                  </span>
                                </Tooltip>
                              ) : (
                                "-"
                              )}
                            </span>
                          </td>
                          <td
                            style={{
                              backgroundColor: device.colorCode,
                              textAlign: "right",
                            }}
                            className={`text-right ${
                              deviceSales[device?.key]?.deviceType === "grand"
                                ? "font-bold"
                                : ""
                            }`}
                          >
                            {device.key === "grand" ? (
                              showOfflineSales ? (
                                deviceSales["grand"] ? (
                                  <>
                                    <div className="totalAmount">
                                      {formatAmount(
                                        getOrderAmount(
                                          deviceSales["grand"],
                                          GMV
                                        ),
                                        getCurrency(env.VENDOR_CODE)
                                      )}
                                      {deviceSales["grand"]?.raiseAlert ? (
                                        <Tooltip
                                          title="GMV seems to be higher than usual, please
                                    deep dive into orders."
                                          arrow
                                        >
                                          <img
                                            src="images/warning-sign.svg"
                                            alt="warning"
                                            style={{
                                              marginLeft: "2px",
                                              width: "15px",
                                              height: "12px",
                                            }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                )
                              ) : deviceSales["online"] ? (
                                <>
                                  <div className="totalAmount">
                                    {formatAmount(
                                      getOrderAmount(
                                        deviceSales["online"],
                                        GMV
                                      ),
                                      getCurrency(env.VENDOR_CODE)
                                    )}
                                    {deviceSales["online"]?.raiseAlert ? (
                                      <Tooltip
                                        title="GMV seems to be higher than usual, please
                                    deep dive into orders."
                                      >
                                        <img
                                          src="images/warning-sign.svg"
                                          alt="warning"
                                          style={{
                                            marginLeft: "2px",
                                            width: "15px",
                                            height: "12px",
                                          }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ) : (
                                "-"
                              )
                            ) : deviceSales[device.key] ? (
                              formatAmount(
                                getOrderAmount(deviceSales[device.key], GMV),
                                getCurrency(env.VENDOR_CODE)
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                        </React.Fragment>
                      )
                    );
                  })}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default DailySalesReport;
