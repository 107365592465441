import React from "react";

const UserDetails = ({ userOverview }) => {
  return (
    <div className="userModalContainer">
      <table
        style={{
          width: "100%",
          borderTop: "1px solid #e8e9ec",
        }}
      >
        <thead>
          <tr className="borderbottom" style={{ width: "100%" }}>
            <th style={{ width: "40%" }} className="contentLeft">
              Customer
            </th>
            <th style={{ width: "30%" }} className="contentRight">
              Total
            </th>
            <th style={{ width: "30%" }} className="contentRight">
              Circle
            </th>
          </tr>
        </thead>

        {userOverview && (
          <tbody>
            <tr>
              <td className="contentLeft">Existing Customers</td>
              <td className="contentRight">
                {userOverview?.existingCustomerCount?.toLocaleString("en-IN")}
              </td>
              <td className="contentRight">
                {userOverview?.existingCircleCustomerCount?.toLocaleString(
                  "en-IN"
                )}
              </td>
            </tr>
            <tr>
              <td className="contentLeft">New Customers</td>
              <td className="contentRight">
                {userOverview?.newCustomerCount?.toLocaleString("en-IN")}
              </td>
              <td className="contentRight">
                {userOverview?.newCircleCustomerCount?.toLocaleString("en-IN")}
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default UserDetails;
