import React from "react";
import CountUp from "react-countup";
import { vendorsList } from "../../lib/config";
import { getCurrency } from "../../utils/helper";

const DailySaleDataCardSummary = ({
  vendorCode,
  ordersCount,
  getOrderAmount,
  onClick,
  currentSlideIndex,
  index,
  missingOnlineSalesObject,
  raiseAlert,
}) => {
  const vendor = vendorsList.find(vendor => vendor.VENDOR_CODE === vendorCode);
  return (
    <>
      <tr>
        <td className={`${vendor.VENDOR_CODE}Light`}>
          <div className="vendors-list" style={{ display: "flex", alignItems: "center"}}>
            <img src={vendor?.FAV_ICON} alt="logo" />
            <h4 className={`${vendor.VENDOR_CODE}`}>{vendor?.TITLE}</h4>
          </div>
        </td>
        <td
          className={`${ordersCount > 0 ? "select-orders" : ""} ${
            vendor.VENDOR_CODE
          }Light`}
        >
          <div className={"orderRevenue"} onClick={onClick}>
            <p className={ordersCount > 0 ? "orderCount font-bold" : ""}>
              {ordersCount?.toLocaleString()}
            </p>
          </div>
        </td>
        <td className={`${vendor.VENDOR_CODE}Light`}>
          <div className={"orderRevenue"}>
            <p>
              {currentSlideIndex === index &&
              missingOnlineSalesObject !== undefined ? (
                <CountUp
                  start={0}
                  end={parseInt(getOrderAmount)}
                  duration={1}
                  delay={0}
                  preserveValue={true}
                  formattingFn={(num) =>
                    num.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      style: "currency",
                      currency: getCurrency(vendor?.VENDOR_CODE),
                    })
                  }
                ></CountUp>
              ) : (
                getOrderAmount.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  style: "currency",
                  currency: getCurrency(vendor?.VENDOR_CODE),
                })
              )}
            </p>
            <span>
              {raiseAlert ? (
                <img
                  src="images/warning-sign.svg"
                  alt="warning"
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "2px",
                    marginBottom: "2px",
                    width: "15px",
                    height: "14px",
                  }}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DailySaleDataCardSummary;
