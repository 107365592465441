import React, { useState, useEffect, Fragment, useContext } from "react";
import MonthlySalesCardSummary from "./monthlySalesCardSummary";
import { DailySalesDataContext } from "../dashboard";
import SnapshotSummary from "../common/snapshotSummary";

const MonthlySalesOverviewMobileSummary = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  salesOverview,
  todayBtnClicked,
  weekClicked,
  getAverageOrderValue,
}) => {
  let periodical = "Monthly";
  if (todayBtnClicked) {
    periodical = "Daily";
  } else if (weekClicked) {
    periodical = "Weekly";
  }

  const [sortedVendordCodes, setSortedVendorCodes] = useState([]);
  const { visibleBrands } = useContext(DailySalesDataContext);
  
  useEffect(() => {
    if (salesOverview) {
      const brands = Object.keys(salesOverview);
      const filteredBrands = brands?.filter(
        (brand) =>
          salesOverview[brand]?.orderCount > 0 && visibleBrands?.includes(brand)
      );
      setSortedVendorCodes(
        filteredBrands.sort(function (a, b) {
          return salesOverview[b]?.orderCount - salesOverview[a]?.orderCount;
        })
      );
    }
  }, [salesOverview, visibleBrands]);

  return (
    <Fragment>
      <div className="mobileSalesTitle">
        <h1>{periodical} Sales Overview</h1>
      </div>
      {/* Snapshot Summary */}
      <div className="mobileSnapshot">
        <SnapshotSummary
          GMV={GMV}
          getOrderAmount={getOrderAmount}
          visibleBrands={visibleBrands}
          salesOverview={salesOverview}
        />
      </div>

      <div className="salesOverviewMobile">
        {sortedVendordCodes?.length > 0 &&
          sortedVendordCodes.map((brand, key) => {
            return (
              <React.Fragment key={key}>
                <MonthlySalesCardSummary
                  ordersCount={salesOverview[brand]?.orderCount}
                  getOrderAmount={getOrderAmount(salesOverview[brand], GMV)}
                  getAverageOrderValue={getAverageOrderValue(
                    salesOverview[brand],
                    GMV,
                    brand
                  )}
                  brand={brand}
                />
              </React.Fragment>
            );
          })}
      </div>
    </Fragment>
  );
};

export default MonthlySalesOverviewMobileSummary;
