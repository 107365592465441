import { envList } from "../lib/config";

export function formatAmount(amount, currency) {
  amount = amount.toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
    currency: currency || "INR",
  });
  amount = leftFillNum(amount, 11);
  return amount;
}

function leftFillNum(num, targetLength) {
  return num.toString().padStart(targetLength, "  ");
}

export function shadeHexColor(color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}
export function getCurrency(vendorCode) {
  let currency;
  if (vendorCode?.match(/are/)) {
    currency = "AED";
  } else if (vendorCode?.match(/sau/)) {
    currency = "SAR";
  } else {
    currency = "INR";
  }
  return currency;
}

// check if we have config & credentials for the vendors received from backend
export function checkIfConfigExists(allowedVendorCodes) {
  // filtered vendor codes for which config exists
  let filteredAllowedVendorCodes = [];
  allowedVendorCodes.forEach((vendor_code) => {
    const configExists = envList.find((config) => config.VENDOR_CODE === vendor_code);
    configExists && filteredAllowedVendorCodes.push(vendor_code);
  });
  return filteredAllowedVendorCodes;
}

// To check if order count has also increased proportionally when the order amount (GMV) has increased by 40%
  export const checkIfOrdersHaveIncreasedProportionally = (
    prevDayOrder,
    todaysOrder
  ) => {
      let orderPercentageIncrease = "";
      let amountPercentageIncrease = "";

      if (todaysOrder?.orderCount > prevDayOrder?.orderCount) {
        orderPercentageIncrease =
          ((todaysOrder?.orderCount - prevDayOrder?.orderCount) /
            prevDayOrder?.orderCount) *
          100;
      }

      if (todaysOrder?.orderAmount > prevDayOrder?.orderAmount) {
        amountPercentageIncrease =
          ((todaysOrder?.orderAmount - prevDayOrder?.orderAmount) /
            prevDayOrder?.orderAmount) *
          100;
      }

      if (Math.round(amountPercentageIncrease) > 40) {
        if (Math.round(orderPercentageIncrease) < 40) {
          return true;
        }
      }

    return false;
  };

export const checkAmountWithPreviousMonthsDate = (
  currentValue,
  previousValue
) => {
  let totalAmountStatus = "";
  if (currentValue && previousValue) {
    if (currentValue - previousValue > 0) {
      totalAmountStatus = "increased";
    } else if (currentValue - previousValue < 0) {
      totalAmountStatus = "decreased";
    } else if (currentValue - previousValue === 0) {
      totalAmountStatus = "no-change";
    }
  }
  return totalAmountStatus;
};

