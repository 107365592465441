import React from "react";
import CountUp from "react-countup";
import { env } from "../../lib/config"
import { getCurrency } from "../../utils/helper";

const DailySalesDataCard = ({
  deviceKey,
  deviceName,
  deviceHidden,
  ordersCount,
  getOrderAmount,
  onClick,
  colorCode,
  currentSlideIndex,
  reportSalesData,
  index,
  raiseAlert,
  status
}) => {
  return (
    <>
      {!deviceHidden && (
        <tr>
          <td
            className={
              deviceKey && deviceKey !== "grand" ? `${deviceKey}` : "totalText"
            }
          >
            <div className={deviceKey && deviceKey !== "grand" ? "icons" : ""}>
              {deviceKey && deviceKey !== "grand" && (
                <img
                  src={`images/${deviceKey.toLowerCase()}.svg`}
                  alt={deviceName}
                />
              )}
              <h4>{deviceName}</h4>
            </div>
          </td>
          <td
            style={{ background: colorCode }}
            className={`${ordersCount > 0 ? "select-orders" : ""}`}
          >
            <div
              className={deviceKey !== "grand" ? "orderRevenue" : "totalAmt"}
              onClick={onClick}
            >
              <p className={ordersCount > 0 ? "orderCount font-bold" : ""}>
                {ordersCount?.toLocaleString()}
              </p>
            </div>
          </td>
          <td style={{ background: colorCode }}>
            <div
              className={deviceKey !== "grand" ? "orderRevenue" : "totalAmt"}
            >
              {currentSlideIndex === index ? (
                <>
                  <p>
                    <CountUp
                      start={0}
                      end={parseInt(getOrderAmount)}
                      duration={1}
                      delay={0}
                      preserveValue={true}
                      formattingFn={(num) =>
                        num.toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: getCurrency(env.VENDOR_CODE),
                        })
                      }
                    ></CountUp>
                  </p>
                  <span>
                    {deviceKey === "grand" && raiseAlert ? (
                      <img
                        src="images/warning-sign.svg"
                        alt="warning"
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "2px",
                          marginBottom: "4px",
                          width: "15px",
                          height: "14px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </>
              ) : (
                getOrderAmount.toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  style: "currency",
                  currency: getCurrency(env.VENDOR_CODE),
                })
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default DailySalesDataCard;
