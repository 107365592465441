import React from "react";
import { deviceTypes } from "../utils/getDeviceTypes";
import { getCurrency, shadeHexColor } from "../utils/helper";
import CountUp from "react-countup";
import { env } from "../lib/config";
import { Tooltip } from "@material-ui/core";

const SalesOverviewTable = ({
  firstDate,
  lastDate,
  GMV,
  getOrderAmount,
  dailySalesData,
  salesOverview,
  setSalesOverview,
  getAverageOrderValue,
  showOfflineSales,
  animated,
  setAnimated,
}) => {
  const filteredDeviceTypes = showOfflineSales
    ? deviceTypes
    : deviceTypes.filter((device) => device.key !== "pos");

  return (
    <>
      <td
        className="text-center"
        style={{
          backgroundColor: shadeHexColor("#D3D3D3", 0.2),
          width: 150,
          background: "#D3D3D3",
          position: "sticky",
          left: 0,
        }}
      >
        Grand Total
      </td>
      {salesOverview &&
        filteredDeviceTypes &&
        filteredDeviceTypes.map((device, key) => {
          return (
            !device.hiddenFor?.includes(env.VENDOR_CODE) && (
              <React.Fragment key={key}>
                <td
                  className="text-right select-orders"
                  style={{
                    backgroundColor: shadeHexColor(device.colorCode, 0.3),
                  }}
                >
                  <span
                    className={`font-bold ${
                      salesOverview[device?.key] && device?.key
                        ? "orderCount1"
                        : ""
                    }
                  `}
                  >
                    {device?.key === "grand" ? (
                      showOfflineSales ? (
                        salesOverview["grand"]?.orderCount > 0 ? (
                          <>
                            <span
                              className={`${salesOverview["grand"]?.orderCountStatus}`}
                            ></span>
                            <Tooltip
                              title={`AOV: ${getAverageOrderValue(
                                salesOverview["grand"],
                                GMV,
                                env.VENDOR_CODE
                              )}`}
                              place="bottom"
                              arrow
                            >
                              <span>
                                {salesOverview[
                                  "grand"
                                ]?.orderCount?.toLocaleString("en-IN")}
                              </span>
                            </Tooltip>
                          </>
                        ) : (
                          "-"
                        )
                      ) : salesOverview["online"]?.orderCount > 0 ? (
                        <>
                          <span
                            className={`${
                              salesOverview["online"]?.orderCountStatus}`}
                          ></span>
                          <Tooltip
                            title={`AOV: ${getAverageOrderValue(
                              salesOverview["online"],
                              GMV,
                              env.VENDOR_CODE
                            )}`}
                            place="bottom"
                            arrow
                          >
                            <span>
                              {salesOverview[
                                "online"
                              ]?.orderCount?.toLocaleString("en-IN")}
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        "-"
                      )
                    ) : salesOverview[device?.key]?.orderCount > 0 ? (
                      <>
                        <span
                          className={`${
                            salesOverview[device?.key]?.orderCountStatus}`}
                        ></span>

                        <Tooltip
                          title={`AOV: ${getAverageOrderValue(
                            salesOverview[device?.key],
                            GMV,
                            env.VENDOR_CODE
                          )}`}
                          place="bottom"
                          arrow
                        >
                          <span>
                            {salesOverview[
                              device?.key
                            ]?.orderCount?.toLocaleString("en-IN")}
                          </span>
                        </Tooltip>
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                </td>
                <td
                  style={{
                    backgroundColor: shadeHexColor(device.colorCode, 0.3),
                  }}
                  className={`text-right ${
                    device?.key === "grand" ? "font-bold" : ""
                  }`}
                >
                  {device?.key === "grand" ? (
                    showOfflineSales ? (
                      salesOverview["grand"] ? (
                        <div className="orderAmount">
                          <span
                            className={`${
                              salesOverview["grand"]?.orderAmountStatus}`}
                          ></span>
                          {!animated ? (
                            <CountUp
                              start={0}
                              end={parseInt(
                                getOrderAmount(salesOverview["grand"], GMV)
                              )}
                              duration={1}
                              startOnMount={false}
                              onEnd={() => setAnimated(true)}
                              formattingFn={(num) =>
                                num.toLocaleString("en-IN", {
                                  minimumFractionDigits: 0,
                                  style: "currency",
                                  currency: getCurrency(env.VENDOR_CODE),
                                })
                              }
                            ></CountUp>
                          ) : (
                            parseInt(
                              getOrderAmount(salesOverview["grand"], GMV)
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              style: "currency",
                              currency: getCurrency(env.VENDOR_CODE),
                            })
                          )}
                        </div>
                      ) : (
                        "-"
                      )
                    ) : salesOverview["online"] ? (
                      <div className="orderAmount">
                        <span
                          className={`${
                            salesOverview["online"]?.orderAmountStatus}`}
                        ></span>
                        {!animated ? (
                          <CountUp
                            start={0}
                            end={parseInt(
                              getOrderAmount(salesOverview["online"], GMV)
                            )}
                            duration={1}
                            startOnMount={false}
                            onEnd={() => setAnimated(true)}
                            formattingFn={(num) =>
                              num.toLocaleString("en-IN", {
                                minimumFractionDigits: 0,
                                style: "currency",
                                currency: getCurrency(env.VENDOR_CODE),
                              })
                            }
                          ></CountUp>
                        ) : (
                          parseInt(
                            getOrderAmount(salesOverview["online"], GMV)
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 0,
                            style: "currency",
                            currency: getCurrency(env.VENDOR_CODE),
                          })
                        )}
                      </div>
                    ) : (
                      "-"
                    )
                  ) : salesOverview[device?.key] ? (
                    <div className="orderAmount">
                      <span
                        className={`${
                          salesOverview[device?.key]?.orderAmountStatus}`}
                      ></span>

                      {!animated ? (
                        <CountUp
                          start={0}
                          end={parseInt(
                            getOrderAmount(salesOverview[device?.key], GMV)
                          )}
                          duration={1}
                          startOnMount={false}
                          onEnd={() => setAnimated(true)}
                          formattingFn={(num) =>
                            num.toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              style: "currency",
                              currency: getCurrency(env.VENDOR_CODE),
                            })
                          }
                        ></CountUp>
                      ) : (
                        parseInt(
                          getOrderAmount(salesOverview[device?.key], GMV)
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 0,
                          style: "currency",
                          currency: getCurrency(env.VENDOR_CODE),
                        })
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </td>
              </React.Fragment>
            )
          );
        })}
    </>
  );
};

export default SalesOverviewTable;
