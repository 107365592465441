import axios from "axios";
import { setEnvByVendorCode, envList } from "./config";

const isServer = () => typeof window === `undefined`;
class GoodGlammAPI {
  HEADERS;
  myGlammV2;
  HEADERS = {
    "Content-Type": "application/json",
  };
    
    constructor(brand) {
        const env = setEnvByVendorCode(envList, brand);        
        this.apiurl = env.apiurl || "https://mag.myglamm.net";
        this.APIVendor = env.VENDOR_CODE;
        this.API_KEY = env.API_KEY || "edfecef6ff6cc50f39eff3fed437533f";
        
        this.myGlammV2 = axios.create({
        baseURL: this.apiurl,
        headers: this.HEADERS,
        vendor: this.APIVendor,
        });

    // Add a request interceptor
    this.myGlammV2.interceptors.request.use(
      function (config) {

        let xaTkn;
        if (!isServer()) {
          xaTkn = localStorage.getItem("xaTkn");
        }

        let { headers } = config;
        if (xaTkn) {
          headers = { ...headers, "x-access-token": xaTkn };
        } else {
          headers = { ...headers, apikey: this.API_KEY };
        }

        return {
          ...config,
          headers,
        };
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.myGlammV2.interceptors.response.use(undefined, (error) => {
      if (error.config && error.response && error.response.status === 401) {
        localStorage.removeItem("xaTkn");
      }
      if (error.config && error.response && error.response.status === 403) {
        localStorage.removeItem("xaTkn");
      }
      if (error.config && error.response && error.response.status === 498) {
        const config = { ...error.config };
        config.headers["x-access-token"] = error.response.data.newToken;
        localStorage.setItem("xaTkn", error.response.data.newToken);
        return this.myGlammV2.request(config);
      }
      return Promise.reject(error);
    });
  }

  /* get day wise data [product sales] and overall grand sales data in one */
  getProductSalesDataAllInOne(firstDate, lastDate, element) {
    return this.myGlammV2.get(
      `/mis-ms/product-sales-data-all-in-one-new/${firstDate}/${lastDate}?vendorCode=${element}`
    );
  }
}

export default GoodGlammAPI;
