import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CountUp from "react-countup";

const SnapshotSummary = ({
  GMV,
  getOrderAmount,
  visibleBrands,
  salesOverview,
  cssClass
}) => {
  const [snapshot, setSnapshot] = useState();
  const [animated, setAnimated] = useState(false);

  const aggregateSalesOverviewByCurrency = (total, brand, currency) => {
    Object.keys(total[currency]).forEach((key) => {
      total[currency][key] += salesOverview[brand]?.[key];
    });
    return total;
  };

  const getSum = (total, vendorCode) => {
    if (vendorCode?.match(/are/)) {
      aggregateSalesOverviewByCurrency(total, vendorCode, "AED");
    } else if (vendorCode?.match(/sau/)) {
      aggregateSalesOverviewByCurrency(total, vendorCode, "SAU");
    } else {
      aggregateSalesOverviewByCurrency(total, vendorCode, "INR");
    }
    return total;
  };

  useEffect(() => {
    visibleBrands?.length > 0 &&
      salesOverview &&
      setSnapshot(
        visibleBrands?.reduce(getSum, {
          INR: { orderAmount: 0, orderCount: 0, payableAmount: 0 },
          AED: { orderAmount: 0, orderCount: 0, payableAmount: 0 },
          SAU: { orderAmount: 0, orderCount: 0, payableAmount: 0 },
        })
      );
  }, [visibleBrands, salesOverview]);

  useEffect(() => {
    setAnimated(false);
  }, [salesOverview, GMV]);

  return (
    <>
      {snapshot &&
        (snapshot["INR"].orderCount > 0 ||
          snapshot["AED"].orderCount > 0 ||
          snapshot["SAU"].orderCount > 0) && (
          <div className={`snapshot ${cssClass ? cssClass : ""}`}>
            <Tabs>
              {/* Tab Headers */}
              <TabList className="tabs">
                {snapshot !== undefined &&
                  Object.keys(snapshot).map((currency, index) => {
                    if (snapshot[currency]?.orderCount > 0)
                      return (
                        <Tab key={index}>
                          <img src={`images/${currency}.png`} width={32} height={32} alt={currency} />
                        </Tab>
                      );
                    return "";
                  })}
              </TabList>

              {/* Tab Content */}
              {snapshot !== undefined &&
                Object.keys(snapshot).map((currency, index) => {
                  if (snapshot[currency]?.orderCount > 0)
                    return (
                      <TabPanel key={index}>
                        <div className="snapshot-data">
                          <div className="revenue">
                            <h3>Revenue</h3>

                            {snapshot[currency]?.payableAmount > 0 ? (
                              <>
                                {!animated ? (
                                  <CountUp
                                    start={0}
                                    end={parseInt(
                                      getOrderAmount(snapshot[currency], GMV)
                                    )}
                                    duration={1}
                                    startOnMount={false}
                                    onEnd={() => setAnimated(true)}
                                    formattingFn={(num) =>
                                      num.toLocaleString("en-IN", {
                                        minimumFractionDigits: 0,
                                        style: "currency",
                                        currency: currency,
                                      })
                                    }
                                  ></CountUp>
                                ) : (
                                  parseInt(
                                    getOrderAmount(snapshot[currency], GMV)
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 0,
                                    style: "currency",
                                    currency: currency,
                                  })
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="orders">
                            <h3>Orders</h3>
                            {snapshot[currency]?.orderCount?.toLocaleString(
                              "en-IN"
                            )}
                          </div>
                        </div>
                      </TabPanel>
                    );
                  return "";
                })}
            </Tabs>
          </div>
        )}
    </>
  );
};

export default SnapshotSummary;
